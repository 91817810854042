import axios from "axios";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IConversionCardRecord, IConversionInfo } from "../interfaces";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";

interface IItemConversionHistory {
    id: number,
    controller: string,
    showModal: boolean,
    setShowModal: any,
}

interface IConversionInfoColumn {
    displayName: string,
    url: string,
    value: IConversionCardRecord,
}

function ItemConversionHistory({id, controller}) {
    const [showConversionModal, setShowConversionModal] = React.useState<boolean>(false);

    return <>
        <ItemConversionHistoryChild id={parseInt(id)} controller={controller} showModal={showConversionModal} setShowModal={setShowConversionModal} />
        <img alt="Conversion History" onClick={() => setShowConversionModal(true)} className="table-img cursor-pointer ml-1" title="Conversion History" src="/images/glyphicons-basic-959-book-library-search.svg" />
    </>
}

function ItemConversionHistoryChild({ id, controller, showModal, setShowModal }: IItemConversionHistory) {
    const url = `/api/${controller}/GetConversionInfo/${id}`;
    const [conversionInfo, setConversionInfo] = React.useState<IConversionInfo>();
    const [error, setError] = React.useState<string>();

    const updateDocumentInView = (url: string) => {
        axios.get<IConversionInfo>(url)
            .then(response => {
                setConversionInfo(response.data);
            })
            .catch(error => {
                if (error.response) {
                    setError(error.response.data);
                }
            })

        setError('');
    }

    React.useEffect(() => {
        updateDocumentInView(url);

        return () => {
            setConversionInfo(null);
        }
    }, [url])

    const getCurrentData = () => {
        if (!conversionInfo) return;

        return <div className="hoverable-div border rounded p-2">
            <Link to={`/${conversionInfo.info.area}/${conversionInfo.info.identifier}/Item/${conversionInfo.info.data[0].documentId}`} className="expanded-link">
                <h2>
                    {`${conversionInfo.info.displayName} ${conversionInfo.info.data[0].documentDescription}`}
                </h2>

                <dl>
                    {
                        conversionInfo.info.details && Object.keys(conversionInfo.info.details).map((key: string) =>
                            <React.Fragment key={key}>
                                <dt>{key}</dt>
                                <dd>{conversionInfo.info.details[key]}</dd>
                            </React.Fragment>)
                    }
                </dl>
            </Link>
        </div>
    }

    const getDownData = () => {
        // TODO: Add logic for ensuring proper ammount of elements are shown for
        // each row.

        if (!conversionInfo || !conversionInfo.down) return;

        const columns: IConversionInfoColumn[] = [];
        conversionInfo.down.map(down => down.data.map(record =>
            columns.push({ url: `/api/${down.controller}/${down.action}/${record.documentId}`, displayName: down.displayName, value: record })
        ));

        const size = 3;
        const rows = [];

        for (let i = 0; i < columns.length; i += size) {
            rows.push(columns.slice(i, i + size));
        }

        return (
            <>
                <div className="text-center">
                    <ArrowDownward />
                </div>

                {
                    rows.map((row, index) => <React.Fragment key={index}>
                        <hr />
                        <div className="row m-2">
                            {
                                row.map(col =>
                                    <div className="border rounded hoverable-div p-2 col-md-4" key={`${col.displayName}${col.value.documentId}`} onClick={() => updateDocumentInView(col.url)} style={{ cursor: "pointer" }}>
                                        <strong>{`${col.displayName} ${col.value.documentDescription}`}</strong>
                                    </div>
                                )
                            }
                        </div>
                    </React.Fragment>)
                }
            </>
        )
    }

    const getUpData = () => {
        // TODO: Add logic for ensuring proper ammount of elements are shown for
        // each row.

        if (!conversionInfo || !conversionInfo.up) return;

        const columns: IConversionInfoColumn[] = []
        conversionInfo.up.map(up => up.data.map(record =>
            columns.push({ url: `/api/${up.controller}/${up.action}/${record.documentId}`, displayName: up.displayName, value: record })
        ));

        const size = 3;
        const rows = [];

        for (let i = 0; i < columns.length; i += size) {
            rows.push(columns.slice(i, i + size));
        }

        return (
            <>
                {
                    rows.map((row, index) => <React.Fragment key={index}>
                        <div className="row m-2">
                            {
                                row.map((col, index) =>
                                    <div className="border rounded hoverable-div p-2 col-md-4" key={index} onClick={() => updateDocumentInView(col.url)} style={{ cursor: "pointer" }}>
                                        <strong>{`${col.displayName} ${col.value.documentDescription}`}</strong>
                                    </div>
                                )
                            }
                        </div>
                        <hr />
                    </React.Fragment>)
                }

                <div className="text-center">
                     <ArrowUpward />
                </div>
            </>
        );
    }

    return <Modal
        onHide={() => setShowModal(false)}
        show={showModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header>
            <h2>Conversion History</h2>
        </Modal.Header>
        <Modal.Body>
            {error && <div className="text-danger">{error}</div>}
            {
                conversionInfo &&
                <div>
                    {getUpData()}
                    {getCurrentData()}
                    {getDownData()}
                </div>
            }
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={() => setShowModal(false)}>Close</Button>
        </Modal.Footer>
    </Modal>
}

export default ItemConversionHistory