import { Configuration } from "@azure/msal-browser";

type AppEnvironment = {
    frontendId: string,
    backendId: string
}

const appEnvironment: AppEnvironment = (() => {
    switch (process.env.REACT_APP_ENVIRONMENT)
    {
        case 'Staging':
            return {
                frontendId: '430d8c9b-375c-4f8a-b3ce-76a89f115dfe',
                backendId: '15e8c865-ea67-44d6-ae76-043fef708ef5'
            }
        case 'Production':
            return {
                frontendId: '0e72c42f-bf1a-493f-be42-a6c19558139f',
                backendId: '4d3d518a-2a5c-49f2-9502-7db1a751fade'
            }
        default:
            return {
                frontendId: '7a343191-9e03-489e-bd77-5c9d1f6a040b',
                backendId: 'c46984a9-9c77-47a2-8565-f776f97fa30a'
            }
    }
})()

export const graphRequest = {
    scopes: ["User.Read"]
}

export const apiRequest = {
    scopes: [`api://${appEnvironment.backendId}/test`]
}

export const configuration: Configuration = {
    auth: {
        clientId: appEnvironment.frontendId,
        authority: "https://login.microsoftonline.com/42bc4a34-f02c-437b-a613-b479f4f9c54a",
        redirectUri: "/",
        postLogoutRedirectUri: "/",
    }
}