import { Link } from "react-router-dom"

function SomethingWentWrong() {
    document.title = "Something went wrong!";

    return (<div className="text-center">
        <div className="mb-2">
            <h2>Something went wrong!</h2>

            <p>It appears that the resource you are trying to access does not exsist.</p>

            <Link className="btn btn-decagon" role="button" to="/">Go Home</Link>
        </div>

        <div>
            <img alt="Decagon Logo" src="/images/Logo.svg" width="200" height="200" />
        </div>
    </div>)
}

export default SomethingWentWrong