import { useParams } from "react-router-dom";
import SomethingWentWrong from "./SomethingWentWrong";

interface ItemRouteProps {
    component: any,
}

function AutoConversionRoute({ component: RouteComponent }: ItemRouteProps) {
    // TODO: Store this information in valid routes somewhere
    const validAutoConversions = [{ area: 'Customer', identifier: 'LaborTimes', action: 'ConvertOpenToInvoice' }]

    const { area, identifier, action } = useParams();

    if (validAutoConversions.findIndex((c: any) => c.area === area && c.identifier === identifier && c.action === action) === -1)
        return <SomethingWentWrong />

    return <RouteComponent />
}

export default AutoConversionRoute;