import { Container } from "react-bootstrap";
import SiteNavBar from "./SiteNavBar";

function Layout({ children }: any) {
    return <>
        <SiteNavBar />

        <Container fluid className="mb-2">
            {children}
        </Container>
    </>
}

export default Layout;