import * as React from "react";

function Privacy() {
    React.useEffect(() => {
        document.title = "Privacy - Decagon"
    }, []);

    return <>
        <h2>Privacy</h2>

        <p>
            We collect telemetry data (such as request headers) for the purpose of diagnosing issues with the application. No information is collected for the purpose of advertising.
            This application requires cookies for logging in the user and identifying your session.
        </p>
    </>
}

export default Privacy;