import * as React from "react";
import Pagination from "@mui/material/Pagination";
import axios from "axios";
import Filtering from "../standalone/Filtering";
import Sorting from "../standalone/Sorting";
import Loading from "../components/Loading";
import CustomNoRowsOverlay from "./CustomNoRowsOverlay";
import SomethingWentWrong from "../components/SomethingWentWrong";
import { DataGrid, GridColDef, GridRowId } from '@mui/x-data-grid';
import { IDocument, DataType, IFilterContext, IValidRoute, IProperty, FieldDisplayType } from "../interfaces.d";
import { Link } from "react-router-dom";
import { InputLabel, MenuItem, Select } from "@mui/material";

type ItemTableProps = {
    routeInfo?: IValidRoute,
    pickerInfo?: IProperty,
    values?: any,
    id?: number,
    independentId?: number,
    selectRecordCallback?: any,
    specialIdentifier?: string,
}

const PAGE_SIZES = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50]

function ItemTable({ routeInfo, pickerInfo, independentId, values, selectRecordCallback, specialIdentifier }: ItemTableProps) {
    const [loading, setLoading] = React.useState<boolean>(true);
    const [pgIndex, setPgIndex] = React.useState<number>(1);
    const [totalPages, setTotalPages] = React.useState(0);
    const [pgSize, setPgSize] = React.useState<number>(20);
    const [filter, setFilter] = React.useState<string>();
    const [sort, setSort] = React.useState<string>();
    const [documentData, setDocumentData] = React.useState<IDocument>();
    const [openedFilterModal, setOpenedFilterModal] = React.useState<string>();
    const lastRenderRef = React.useRef(false);

    const rowIdentifier = React.useMemo(() => documentData?.schema.find(s => s.isKey).propertyName, [documentData]);
    const spanIdentifier = React.useMemo(() => documentData?.schema.find(s => s.displayType === FieldDisplayType.Span).propertyName, [documentData]);

    const fetchUrl = React.useMemo(() => {
        let skip = pgSize * (pgIndex - 1);
        if (skip < 0) skip = 0;

        let path: string;
        if (routeInfo)
            path = `/api/${routeInfo.controller}${independentId ? `?independentId=${independentId}` : ''}`
        else if (pickerInfo) {
            path = pickerInfo.fetchUri;

            if (pickerInfo.dependentRelationship) {
                path += `${pickerInfo.dependentRelationship.propertyName ? `?${pickerInfo.dependentRelationship.propertyName}=${values[pickerInfo.dependentRelationship.propertyName] !== "" ? values[pickerInfo.dependentRelationship.propertyName] : 0} ` : ''}`;
            }
            else if (pickerInfo.dependentRelationships) {
                pickerInfo.dependentRelationships.forEach(dp => {
                    let prefix = path.indexOf("?") === -1 ? '?' : '&';

                    path += `${prefix}${dp.propertyName}=${values[dp.propertyName] ?? 0}`;
                });
            }
        }

        let prefix = path.indexOf("?") === -1 ? '?' : '&';
        path = path.trim();

        if (skip) {
            path += `${prefix}skip=${skip}`;
            if (prefix === '?') prefix = '&';
        }

        if (pgSize) {
            path += `${prefix}top=${pgSize}`;
            if (prefix === '?') prefix = '&';
        }

        if (sort) {
            path += `${prefix}orderBy=${sort}`;
            if (prefix === '?') prefix = '&';
        }

        if (filter) {
            path += `${prefix}filter=${filter}`;
            if (prefix === '?') prefix = '&';
        }

        return path;
    }, [pgSize, pgIndex, routeInfo, independentId, pickerInfo, values, sort, filter]);

    React.useEffect(() => {
        if (lastRenderRef.current) return

        axios.get<IDocument>(fetchUrl)
        .then(records => {
            setDocumentData(records.data);
            setLoading(false);
        })
        .catch(_err => {
            setLoading(false);
        })

    }, [fetchUrl])

    React.useEffect(() => {
        return () => { lastRenderRef.current = true }
    }, [independentId])

    React.useEffect(() => {
        if (documentData) {
            const totalPages = Math.ceil(documentData.totalCount / pgSize);
            if (pgIndex > totalPages) setPgIndex(totalPages);

            setTotalPages(totalPages);
        }
    }, [pgIndex, pgSize, documentData])

    const updateFilter = (newFilter: string) => setFilter(newFilter);

    const updateOpenedModal = (newModal: string) => setOpenedFilterModal(newModal);

    const handleIndexChange = (_event: any, value: number) => setPgIndex(value);

    const handleSizeChange = (event: any) => setPgSize(parseInt(event.target.value));

    const prepareColumns = React.useMemo(() => {
        const columns: GridColDef[] = [];

        function getTableData(property: IProperty, value: any, rowId: GridRowId) {
            if (!documentData) return;
    
            let display: any = value;
            switch (property.type) {
                case DataType.Date:
                    display = (value !== null) ? new Date(value).toLocaleDateString() : '';
                    break;
                case DataType.DateTime:
                    display = (value !== null) ? new Date(value).toLocaleString() : '';
                    break;
                case DataType.Boolean:
                    display = <input readOnly type="checkbox" checked={value === 'True'} />;
                    break;
                case DataType.Dropdown:
                    display = property.staticChoices.find(c => c.fieldName === value)?.displayName;
                    break;
                default:
                    break;
            }
    
            if (typeof selectRecordCallback === "undefined" && typeof routeInfo !== "string") display = (
                <Link
                    className="link-row pr-1000px" 
                    to={`/${routeInfo.area}/${routeInfo.identifier}/Item/${independentId ? `${independentId}/${specialIdentifier}/${rowId}` : rowId}`}>
                        {display}
                </Link>
            )
    
            return display;
        }

        documentData?.schema.filter(p => !p.isKey && p.displayOrder !== -1).forEach(p => {
            columns.push({ 
                field: p.propertyName,
                headerName: p.displayName ?? p.propertyName,
                sortable: false,
                minWidth: 180,
                flex: 1,
                renderHeader: () => <span data-testid={p.propertyName} style={{ paddingRight: '180px' }} onClick={() => setOpenedFilterModal(p.propertyName)} className="cursor-pointer">{p.displayName ?? p.propertyName}</span>,
                renderCell: (params) => <span className="cursor-pointer pr-1000px">&nbsp;{getTableData(p, params.value, params.id)}</span> });
        });

        return columns;
    }, [documentData, independentId, routeInfo, selectRecordCallback, specialIdentifier])

    const prepareRows = React.useMemo(() => {
        const rows: any = []

        documentData?.data.forEach((d, index) => {
            const rowData: any = { id: d.records.find(r => r.propertyName === documentData.schema.find(p => p.isKey).propertyName).value }
            d.records.forEach(r => rowData[r.propertyName] = r.displayValue ?? r.value);
            rows.push(rowData);
        })

        return rows;
    }, [documentData])

    function ItemTablePagination() {
        return (<div className="mt-2 mb-1">
            <Pagination style={{ display: 'inline' }} count={totalPages} page={pgIndex === 0 ? 1 : pgIndex} onChange={handleIndexChange} />

            <div className="text-right mr-1">
                <InputLabel className="mr-2" style={{display: 'inline'}} id="size-label">Pg. Size</InputLabel>
                <Select sx={{ mt: .5 }} size="small" variant="outlined"
                    labelId="size-label"
                    label="Pg. Size"
                    value={pgSize}
                    onChange={handleSizeChange}
                >
                    {
                        PAGE_SIZES.map(size => <MenuItem key={size} value={size}>{size}</MenuItem>)
                    }
                </Select>
            </div>
        </div>)
    }

    return (
        <>
            {
                loading ? <Loading /> : documentData ? <>
                    <Sorting
                        setSort={setSort}
                        properties={documentData.schema.filter(s => s.displayOrder !== -1)}
                    />

                    <FilterContext.Provider value={{ openedModal: openedFilterModal, setOpenedModal: updateOpenedModal }}>
                        <Filtering
                            filter={filter}
                            setFilter={updateFilter}
                            properties={documentData.schema.filter(s => s.displayOrder !== -1)}
                        />
                    </FilterContext.Provider>

                    <div style={{ width: '100%' }}>
                        <DataGrid
                            autoHeight
                            rows={prepareRows}
                            columns={prepareColumns}
                            components={{
                                NoRowsOverlay: CustomNoRowsOverlay,
                                Pagination: ItemTablePagination,
                            }}
                            onCellClick={(params) => {
                                if (typeof (selectRecordCallback) !== 'undefined') {
                                    const record = documentData?.data.find(d => d.records.find(r => r.propertyName === rowIdentifier && r.value === params.id))
                                        .records.find(r => r.propertyName === spanIdentifier)

                                    selectRecordCallback(params.id, record)
                                }
                            }}
                            disableSelectionOnClick
                            disableColumnFilter
                            disableColumnMenu
                        />
                    </div>
                </> : <SomethingWentWrong />
            }
        </>
    );
}

export const FilterContext = React.createContext<IFilterContext>(null);
export default ItemTable;