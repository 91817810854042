import React from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { DataType, IDocument, IValidRoute, IValidRouteChild, IRecord, ItemDisplayType, IBaseInfo, ActionType, ItemParams, FieldDisplayType } from "../interfaces.d";
import ItemIndex from "./ItemIndex";
import Loading from "../components/Loading";
import ItemConversionHistory from "./ItemConversionHistory";

type ItemDisplayProps = {
    baseInfo: IBaseInfo,
    data: IValidRoute,
    dependentObj: IValidRouteChild,
    documentData: IDocument,
    itemDisplayType: ItemDisplayType,
    handleImgClick(displayType: ItemDisplayType | null) : void,
}

function ItemDisplay({ baseInfo, data, dependentObj, documentData, itemDisplayType, handleImgClick } : ItemDisplayProps) {
    const { area, controller, id, independentId } = useParams<ItemParams>();
    const [deleteError, setDeleteError] = React.useState<string>();
    const navigate = useNavigate();

    const handleDelete = async (e: any) => {
        e.preventDefault();

        try {
            await axios.delete(`/api/${dependentObj?.controller ?? data.controller}/${id}`);
            navigate(`/${area}/${controller}/${independentId ? `Item/${independentId}` : ''}`);
        }
        catch (error) {
            if (error.response) {
                setDeleteError(error.response.data);
            }
        }
    }

    function getDetailedList() {
        return <dl className="row">
            {
                documentData.schema.filter(p => p.displayOrder !== -1 && documentData.data[0].records.find(r => r.propertyName === p.propertyName)).map(property => {
                    const record: IRecord = documentData.data[0].records.find(r => r.propertyName === property.propertyName)

                    const getDisplayValue = () => {

                        if (property.displayType === FieldDisplayType.TextArea) return <div className="notes">{record.value}</div>

                        switch (property.type) {
                            case DataType.Date:
                                return <>{(record.value !== null) ? new Date(record.value).toLocaleDateString() : ''} &nbsp;</>
                            case DataType.DateTime:
                                return <>{(record.value !== null) ? new Date(record.value).toLocaleString() : ''} &nbsp;</>
                            case DataType.Boolean:
                                return <input onClick={(e) => e.preventDefault()} readOnly checked={record.value} type="checkbox" aria-label="Checkbox for following text input" />
                            case DataType.Dropdown:
                                return <>{property.staticChoices.find(c => parseInt(c.fieldValue) === record.value)?.displayName}</>
                            default:
                                return <>{record.displayValue ?? record.value} &nbsp;</>
                        }

                    }

                    return <React.Fragment key={property.propertyName}>
                        <dt className="col-sm-2">{property.displayName ?? property.propertyName}</dt>
                        <dd className={`col-sm-10 ${property.type === DataType.Textarea ? 'notes' : ''}`}>{getDisplayValue()}</dd>
                    </React.Fragment>
                })
            }
        </dl>
    }

    function getHeader() {
        let actionTitle = '';

        switch (itemDisplayType) {
            case ItemDisplayType.History:
                actionTitle = 'History';
                break
            case ItemDisplayType.Details:
                actionTitle = 'Details';
                break
            case ItemDisplayType.Delete:
                actionTitle = 'Delete';
                break
            default:
                break
        }

        return <>
            <h2>{actionTitle}</h2>
            <h4>{dependentObj ? (dependentObj.displayName ?? dependentObj.controller) : (data.display ?? controller)}</h4>
            <hr />
        </>
    }

    function getBody() {
        switch (itemDisplayType) {
            case ItemDisplayType.History:
                break
            case ItemDisplayType.Details:
                return <>
                    {getDetailedList()}

                    {
                        baseInfo?.childDisplayTable &&
                        <ItemIndex
                            independentId={parseInt(id)}
                            info={baseInfo.childDisplayTable}
                        />
                    }

                    {
                        baseInfo?.childDisplayTables?.map((cd, index) => <React.Fragment key={index}>
                            <ItemIndex
                                independentId={parseInt(id)}
                                info={cd}
                            />

                            {index + 1 !== baseInfo.childDisplayTables.length && <hr />}
                        </React.Fragment>)
                    }
                </>
            case ItemDisplayType.Delete:
                return <>
                    {getDetailedList()}

                    {deleteError && <div className="text-danger">{deleteError}</div>}
                    <Form onSubmit={handleDelete}>
                        <Button type="submit">Delete</Button>
                    </Form>
                </>;
            default:
                return <></>
        }

        return <></>
    }

    function getSpecialActions() {
        switch (baseInfo.specialActions) {
            case ActionType.ConversionHistory:
                return <ItemConversionHistory id={id} controller={data.controller} />
            default:
                return
        }
    }

    function getToolbar() {
        return <>
            <div className="mt-2 justify-content-center row bg-light mx-auto p-2">
                <Link to={`/${area}/${controller}${independentId ? `/Item/${independentId}` : ''}`}><img alt="Back" className="table-img cursor-pointer mb-2" title="Back" src="/images/glyphicons-basic-223-chevron-left.svg" /></Link>
                <img alt="Edit" onClick={() => { handleImgClick(null) }} title="Edit" className="table-img ml-1 cursor-pointer" src="/images/glyphicons-basic-31-pencil.svg" />
                <img alt="Delete" onClick={() => handleImgClick(ItemDisplayType.Delete)} className={`table-img ml-1 ${itemDisplayType === ItemDisplayType.Delete ? "half-opacity" : "cursor-pointer"}`} title="Delete" src="/images/glyphicons-basic-17-bin.svg" />
                {
                    (!independentId && baseInfo && baseInfo.specialActions !== undefined && baseInfo.specialActions !== 0) && getSpecialActions()
                }

                {
                    baseInfo?.actions?.filter(a => !a.indexOnly).map(a => {
                        let href = '';

                        if (dependentObj) {
                            href = `/${a.area}/${data.identifier}/Item/${independentId}/${dependentObj.identifier}/${id}/${a.action}`;
                        }
                        else {
                            href = `/${a.area}/${data.identifier}/Item/${id}/${a.action}`;
                        }

                        return <Link key={a.action} title={a.displayName ?? a.action} to={href}><img alt={a.displayName ?? a.action} className="table-img cursor-pointer ml-1 mb-2" src={a.imgUrl} /></Link>
                    })
                }
            </div>
        </>
    }

    return <div>
        {getHeader()}
        {documentData.data ? <>
                {getBody()}
                {getToolbar()}
            </> : <Loading />}
    </div>
}

export default ItemDisplay