import * as React from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Table, PersonBadgeFill, CloudFill, Facebook, Linkedin } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { DataContext } from "../App";
import Loading from "./Loading";

function Home() {
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const dataContext = React.useContext(DataContext);

    React.useEffect(() => {
        document.title = "Home - Decagon";
    }, []);

    const LoggedInPage = () => {
        return dataContext ? <>
            <div className="text-center">
                <h1 className="display-4">Welcome to Decagon</h1>
            </div>
            <div className="row my-3">
                <div className="col-sm-6">
                    <div className="card h-100">
                        <div className="card-body">
                            <h5 className="card-title">Home</h5>
                            <ul className="list-group">
                                <li className="list-group-item"><Link className="list-group-item-action" to="/Home/About">About</Link></li>
                                <li className="list-group-item"><Link to="/Home/Contact" className="list-group-item-action">Contact</Link></li>
                                <li className="list-group-item"><Link to="/Home/Privacy" className="list-group-item-action">Privacy</Link></li>
                                <li className="list-group-item"><span className="list-group-item-action cursor-pointer" onClick={() => instance.logout()}>Sign Out</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="card h-100">
                        <div className="card-body">
                            <h5 className="card-title">Customers</h5>
                            <ul className="list-group">
                                {
                                    dataContext.filter(dc => dc.area === "Customer").map(vr => <li key={vr.identifier} className="list-group-item"><Link className="list-group-item-action" to={`/${vr.area}/${vr.identifier}`}>{vr.pluralDisplay ?? vr.controller}</Link></li>)
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row my-3">
                <div className="col-sm-6">
                    <div className="card h-100">
                        <div className="card-body">
                            <h5 className="card-title">Core</h5>
                            <ul className="list-group">
                                {
                                    dataContext.filter(dc => dc.area === "Core" || dc.area === "Accounting").map(vr => <li key={vr.identifier} className="list-group-item"><Link className="list-group-item-action" to={`/${vr.area}/${vr.identifier}`}>{vr.pluralDisplay ?? vr.controller}</Link></li>)
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="card h-100">
                        <div className="card-body">
                            <h5 className="card-title">Vendors</h5>
                            <ul className="list-group">
                                {
                                    dataContext.filter(dc => dc.area === "Vendor").map(vr => <li key={vr.identifier} className="list-group-item"><Link className="list-group-item-action" to={`/${vr.area}/${vr.identifier}`}>{vr.pluralDisplay ?? vr.controller}</Link></li>)
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="text-center">
                <img alt="Decagon Logo" src="/images/Logo.svg" width="250" height="250" />
            </div>
        </> : <Loading />
    }

    const LandingPage = () => {
        return <>
            <div className="row text-center pt-4 pb-4 mb-4 text-white bg-decagon">
                <div className="col-md-6 align-self-center mb-2">
                    <h2>Enterprise Planning Modernized</h2>
                        
                    <p>
                        Bring your company's workflow into the future with
                        an advanced ERP application that is customizable to your needs. 
                    </p>

                    <a className="btn btn-light" href="http://febyte.com/business-intelligence">Learn More</a>

                    <div className="text-center mt-4">
                        <em className="mr-2">Find us on</em>

                        <a title="Facbook" href="https://fb.me/febytecorp"> 
                            <Facebook className="mr-2" size={25} color="white" />
                        </a>

                        <a title="Linkedin" href="https://www.linkedin.com/company/febyte">
                            <Linkedin size={25} color="white" />
                        </a>
                    </div>
                </div>

                <div className="col-md-6">
                    <img alt="Decagon Logo" className="white-svg" src="/images/Logo.svg" width="250" height="250"></img>
                </div>
            </div>

            <h4 className="color-decagon">Taking management to the next level</h4>

            <div className="row color-decagon text-center pt-4 pb-4 mb-4">
                <div className="col-md-4">
                    <Table size={35} color="#122447" />
                    <h6 className="mb-2 mt-2">Data refined and organized</h6>
                    <p>
                        Create, update, delete all with a few clicks. With our advanced components that support filtering and sorting
                        logic you can quickly and easily manage your data on a day to day basis.
                    </p>
                </div>

                <div className="col-md-4">
                    <PersonBadgeFill size={35} color="#122447" />
                    <h6 className="mb-2 mt-2">User permissions</h6>
                    <p>
                        You make the decisions, decide what users are allowed to access specific data
                        and what they can modify.
                    </p>
                </div>

                <div className="col-md-4">
                    <CloudFill size={35} color="#122447" />
                    <h6 className="mb-2 mt-2">Cloud performance</h6>
                    <p>
                        With the power of the cloud, you can expect integration and speed like never before.
                        Quickly access your data anywhere, at home, on the go you name it!
                    </p>
                </div>
            </div>
        </>
    }

    return (isAuthenticated ? <LoggedInPage /> : <LandingPage />);
}

export default Home;