import "./App.scss";
import Home from "./components/Home";
import About from "./components/About";
import Contact from "./components/Contact";
import Privacy from "./components/Privacy";
import Layout from "./components/Layout";
import ItemIndex from "./item/ItemIndex";
import Item from "./item/Item";
import StandaloneConversion from "./generic/StandaloneConversion";
import { Route, Routes } from "react-router-dom"
import { createContext, Fragment } from "react";
import { IValidRoute } from "./interfaces";
import ItemRoute from "./components/ItemRoute";
import StandardLineConversion from "./generic/StandardLineConversion";
import DocumentTotals from "./generic/DocumentTotals";
import AutoConversion from "./generic/AutoConversion";
import Price from "./generic/Price";
import SomethingWentWrong from "./components/SomethingWentWrong";
import ItemTemporalHistory from "./item/ItemTemporalHistory";
import AutoConversionRoute from "./components/AutoConversionRoute";

function App({validRoutes, childIdentifiers}) {
    return (
        <DataContext.Provider value={validRoutes}>
            <Layout>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/Home/About" element={<About />} />
                    <Route path="/Home/Contact" element={<Contact />} />
                    <Route path="/Home/Privacy" element={<Privacy />} />
                    <Route path="/:area/:controller/" element={<ItemRoute component={ItemIndex} />} />
                    <Route path="/:area/:identifier/:action" element={<AutoConversionRoute component={AutoConversion} />} />
                    <Route path="/:area/:controller/Item" element={<ItemRoute component={Item} />} />

                    {
                        childIdentifiers.map(ci =>
                            <Fragment key={ci}>
                                <Route path={`/:area/:controller/Item/:independentId/${ci}`} element={<ItemRoute component={Item} />} />
                                <Route path={`/:area/:controller/Item/:independentId/${ci}/:id`} element={<ItemRoute component={Item} />} />
                            </Fragment>
                        )
                    }

                    <Route path={`/:area/:identifier/Item/:id/History`} element={<ItemRoute component={ItemTemporalHistory} />} />
                    <Route path={`/:area/:identifier/Item/:id/Document`} element={<ItemRoute component={DocumentTotals} />} />
                    <Route path={`/:area/:identifier/Item/:id/Price`} element={<ItemRoute component={Price} />} />
                    <Route path={`/:area/:controller/Item/:independentId/:childIdentifier/:id/:action`} element={<ItemRoute component={StandaloneConversion} />} />
                    <Route path="/:area/:controller/Item/:id" element={<ItemRoute component={Item} />} />
                    <Route path="/:area/:controller/Item/:id/Copy" element={<ItemRoute component={StandaloneConversion} />} />
                    <Route path="/:area/:controller/Item/:id/:action" element={<ItemRoute component={StandardLineConversion} />} />
                    <Route path="*" element={<SomethingWentWrong />} />
                </Routes>
            </Layout>
        </DataContext.Provider>
    );
}

export const DataContext = createContext<IValidRoute[]>(null);
export default App;