import React from "react"; 
import { useParams } from "react-router-dom";
import { DataContext } from "../App";
import SomethingWentWrong from "../components/SomethingWentWrong";
import ItemTemporalTable from "./ItemTemporalTable";

function ItemTemporalHistory() {
    const { id, identifier } = useParams();
    const dataContext =  React.useContext(DataContext).find(vr => vr.identifier === identifier);
    const [hasInitialError, setHasInitialError] = React.useState<boolean>(false);

    const updateHasInitialError = (hasError: boolean) => setHasInitialError(hasError);

    return (!dataContext || hasInitialError) ? <SomethingWentWrong /> : <>
        <h2>{dataContext.display ?? dataContext.controller} History</h2>
        <h4 className="ml-2">Id: {id}</h4>
        <hr />
        <ItemTemporalTable id={id} apiController={dataContext.controller} updateHasInitialError={updateHasInitialError} />
    </>
}

export default ItemTemporalHistory