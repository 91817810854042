import axios from "axios";
import React from "react";
import { Spinner } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DataContext } from "../App";
import Loading from "../components/Loading";
import SomethingWentWrong from "../components/SomethingWentWrong";
import { IPricingInfo, IPricingLine, MarkupType, SelectedPricingLine } from "../interfaces.d";

type PriceParams = {
    id: string,
    area: string,
    identifier: string,
}

function Price() {
    const { id, area, identifier } = useParams<PriceParams>();
    const [initialError, setInitialError] = React.useState<boolean>(false);
    const [data, setData] = React.useState<IPricingInfo>();
    const [error, setError] = React.useState<string>();
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
    const [selectedLines, setSelectedLines] = React.useState<SelectedPricingLine[]>([]);
    const dataContext = React.useContext(DataContext).find(dc => dc.identifier === identifier);
    const controller = dataContext?.controller;
    const navigate = useNavigate();
    document.title = `${dataContext.display ?? dataContext.identifier} ${data?.parentDescription ?? ''} Price - Decagon`;

    React.useEffect(() => {
        axios.get<IPricingInfo>(`/api/${controller}/GetPriceInfo/${id}`)
            .then(response => setData(response.data))
            .catch(_err => setInitialError(true))
    }, [controller, id])

    const updateSelectedLines = (lines: SelectedPricingLine[]) => setSelectedLines(lines);

    function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        setIsSubmitting(true);
        setError('');

        axios.post(`/api/${controller}/Price/${id}`, selectedLines)
            .then(_response => navigate(`/${area}/${identifier}/Item/${id}`))
            .catch(err => {
                if (err.response) {
                    setIsSubmitting(false);
                    setError(err.response.data.title ? err.response.data.title : err.response.data);
                }
            });
    }

    return (initialError ? <SomethingWentWrong /> :
        !data ? <Loading /> :
            <>
                <h2>{data.title ?? controller}</h2>
                {error && <div className="form-error">&bull; {error}</div>}

                {
                    data.lines.length === 0 ? <div className="alert alert-light">This document does not contain any priceable lines.</div> :
                        <form onSubmit={handleSubmit}>
                            <button type="submit" disabled={selectedLines.length === 0 || isSubmitting} className="btn btn-primary">Price {isSubmitting && <Spinner animation="border" className="ml-2" size="sm" color="white" />}</button>

                            <div className="mt-2 mb-2">
                                {
                                    data.lines.map(line => <div className="rounded table-responsive border mt-2 mb-2" key={line.id}>
                                        {<PricingLine
                                            data={data}
                                            info={line}
                                            selectedLines={selectedLines}
                                            updateSelectedLines={updateSelectedLines}
                                        />}
                                    </div>)
                                }
                            </div>

                            <button type="submit" disabled={selectedLines.length === 0 || isSubmitting} className="btn btn-primary">Price {isSubmitting && <Spinner animation="border" className="ml-2" size="sm" color="white" />}</button>
                        </form>
                }

                <div className="mt-2">
                    <Link to={`/${area}/${identifier}/Item/${id}`}><img alt="Back" className="table-img cursor-pointer mb-2" title="Back" src="/images/glyphicons-basic-223-chevron-left.svg" /></Link>
                </div>
            </>
        )
}

type PricingLineProps = {
    data: IPricingInfo,
    info: IPricingLine,
    selectedLines: SelectedPricingLine[],
    updateSelectedLines(lines: SelectedPricingLine[]) : void,
}

function PricingLine ({ data, info, selectedLines, updateSelectedLines,  } : PricingLineProps) {
    const markupInputRef = React.useRef<HTMLInputElement | null>(null);

    function handleClear(lineId: number) {
        markupInputRef.current.value = '';

        let selectedLinesCopy = [...selectedLines];
        selectedLinesCopy = selectedLinesCopy.filter(sl => sl.id !== lineId);
        updateSelectedLines(selectedLinesCopy);
    }

    function updateMarkupValue(e: React.ChangeEvent<HTMLInputElement>, lineId: number) {
        let selectedLinesCopy = [...selectedLines]

        const selectedLine = selectedLinesCopy.find(sl => sl.id === lineId);
        if (selectedLine) {
            try {
                selectedLine.markupInfo.value = e.currentTarget.value;
            }
            catch {
                selectedLine.markupInfo.value = null;
            }
        }
    }

    function updateMarkupType(e: React.ChangeEvent<HTMLSelectElement>, lineId: number) {
        let selectedLinesCopy = [...selectedLines]

        const selectedLine = selectedLinesCopy.find(sl => sl.id === lineId);
        if (selectedLine) {
            switch (e.currentTarget.value) {
                case "Percent":
                    selectedLine.markupInfo.type = MarkupType.Percent;
                    break;
                case "Fixed":
                    selectedLine.markupInfo.type = MarkupType.Fixed;
                    break;
                case "To":
                    selectedLine.markupInfo.type = MarkupType.To;
                    break;
            }
        }
    }

    function handleRadioButton(e: React.ChangeEvent<HTMLInputElement>, document: string) {
        // name === lineId; value === childId

        const selectedLine: SelectedPricingLine = {
            id: parseInt(e.currentTarget.name),
            childId: parseInt(e.currentTarget.value),
            childName: document,
            markupInfo: { type: MarkupType.Percent, value: null },
        };

        let selectedLinesCopy = [...selectedLines];
        if (selectedLines.find(sl => sl.id === parseInt(e.currentTarget.name)))
            selectedLinesCopy = selectedLinesCopy.filter(sl => sl.id !== parseInt(e.currentTarget.name));

        selectedLinesCopy.push(selectedLine);
        updateSelectedLines(selectedLinesCopy);
    }

    return (<>
        <table className="table-sm ml-2">
            <thead>
                <tr>
                    {
                        data.parentSchema.map(p => <th key={p.propertyName}>{p.propertyName}</th>)
                    }
                </tr>
            </thead>

            <tbody>
                {
                    <tr>
                        {
                            info.parent.map(r => <td key={r.propertyName}>{r.value}</td>)
                        }
                    </tr>
                }
            </tbody>
        </table>

        <div className="border rounded p-2 m-2">
            <div className="input-group">
                <div className="input-group-prepend">
                    <div className="input-group-text">
                        Markup
                    </div>

                    <select onChange={(e) => updateMarkupType(e, info.id)} disabled={selectedLines.findIndex(sl => sl.id === info.id) === -1} className={`input-group-text form-control ${selectedLines.findIndex(sl => sl.id === info.id) !== -1 ? "bg-light" : ""}`}>
                        <option value="Percent">Percent</option>
                        <option value="Fixed">Fixed</option>
                        <option value="To">To</option>
                    </select>
                </div>
                <input ref={markupInputRef} onChange={(e) => updateMarkupValue(e, info.id)} disabled={selectedLines.findIndex(sl => sl.id === info.id) === -1} className="form-control" />
            </div>

            <table className="table-sm w-100">
                <thead>
                    <tr>
                        <th>{selectedLines.findIndex(sl => sl.id === info.id) !== -1 && <img onClick={(e) => handleClear(info.id)} title="Clear Selection" alt="Clear Selection" className="table-img cursor-pointer" src="/images/glyphicons-basic-250-eraser.svg" />}</th>
                        {
                            data.childSchema.map(p => <th key={p.propertyName}>{p.propertyName}</th>)
                        }
                    </tr>
                </thead>

                <tbody>
                    {
                        info.children.map(c => <tr key={c.id}>
                            <td><input checked={selectedLines.findIndex(sl => sl.id === info.id && sl.childId === c.id) !== -1} onChange={(e) => handleRadioButton(e, c.name)} type="radio" name={info.id.toString()} value={c.id} /></td>
                            {
                                c.records.map(r => <td key={r.propertyName}>{r.value}</td>)
                            }
                        </tr>)
                    }
                </tbody>
            </table>
        </div>
    </>)
}

export default Price;