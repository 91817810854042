import 'bootstrap/dist/css/bootstrap.css';
import ReactDOM from 'react-dom';
import { MsalAuthenticationTemplate, MsalProvider, useMsal } from "@azure/msal-react";
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { apiRequest, configuration } from "./authConfig";
import { IValidRoute } from './interfaces';
import React from 'react';
import axios from 'axios';
import Loading from './components/Loading';

import { LicenseInfo } from '@mui/x-data-grid-pro'

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUIXPRO_KEY)

const pca = new PublicClientApplication(configuration);

const AppProvider = () => (
    <MsalProvider instance={pca}>
        <BrowserRouter>
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={apiRequest}
            >
                <Body />
            </MsalAuthenticationTemplate>
        </BrowserRouter>
    </MsalProvider>
);

const Body = () => {
    const { instance, accounts } = useMsal();
    const [validRoutes, setValidRoutes] = React.useState<IValidRoute[]>()
    const [childIdentifiers, setChildIdentifiers] = React.useState<string[]>([]);
    const [accessToken, setAccessToken] = React.useState<string>();

    React.useEffect(() => {
        const tokenReq = {
            ...apiRequest,
            account: accounts[0]
        }

        if (instance) {
            instance.acquireTokenSilent(tokenReq).then((response) => {
                setAccessToken(`Bearer ${response.accessToken}`);
            }).catch((e) => {
                instance.acquireTokenPopup(tokenReq).then((response) => {
                    setAccessToken(`Bearer ${response.accessToken}`);
                })
            })
        }
    }, [instance, accounts])

    React.useEffect(() => {
        if (accessToken) {
            axios.defaults.headers.common['Authorization'] = accessToken;

            axios.get<IValidRoute[]>('/api/App')
                .then(response => {
                    setValidRoutes(response.data)

                    const childIdentifiers: string[] = [];
                    response.data.filter(d => d.children).forEach(d => d.children.forEach(c => childIdentifiers.indexOf(c.identifier) === -1 && childIdentifiers.push(c.identifier)));
                    setChildIdentifiers(childIdentifiers);
                });
        }
    }, [accessToken]);

    return (validRoutes && childIdentifiers) ? <App validRoutes={validRoutes} childIdentifiers={childIdentifiers} /> : <Loading />
}

ReactDOM.render(<AppProvider />, document.getElementById("root"));