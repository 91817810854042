export type ItemParams = {
    area: string,
    controller: string,
    action: string,
    independentId?: string,
    id?: string,
}

export const enum ItemDisplayType {
    Details,
    Delete,
    History,
}

export const enum ActionType {
    Default,
    ConversionHistory,
    RecordTracing,
}

// Contexts
export interface IFilterContext {
    openedModal: string,
    setOpenedModal(modal: string)
}

// Invoice / Quote Document
export type PdfDocument = {
    document: string,
    description: string,
    vendor1: {[key: string]: any},
    vendor2: {[key: string]: any},
    client: string[],
    tables: PdfTable[],
    customerNotes: { [key: string]: string }[],
    subtotal: string,
    misc: string,
    shipping: string,
    tax: string,
    grandTotal: string,
}

type PdfTable = {
    headings: string[],
    rows: {[key: string]: any }[],
}

// Conversion
export interface IPricingInfo {
    title: string,
    parentDescription: string,
    parentSchema: IProperty[],
    childSchema: IProperty[],
    lines: IPricingLine[],
}

export interface IPricingLine {
    id: number,
    badgeText: string,
    parent: IRecord[],
    children: IPricingLineChild[],
}

export interface IPricingLineChild {
    id: number,
    name: string,
    records: IRecord[],
}

export type SelectedPricingLine = {
    id: number,
    childId: number,
    childName: string,
    markupInfo?: IMarkup,
}

export interface IMarkup {
    type: MarkupType,
    value: any,
}

export const enum MarkupType {
    Percent,
    Fixed,
    To,
}

export interface IAutoConversion {
    title: string,
    convertToArea: string,
    convertToIdentifier: string,
    parentDisplayName: string,
    convertedDisplayName: string,
    parentSchema: IProperty[],
    childSchema: IProperty[],
    convertedSchema: IProperty[],
    lines: IAutoConversionLine[],
}

export interface IAutoConversionLine {
    id: number,
    isNew: boolean,
    parent: IRecord[],
    children: IAutoConversionLineChild[],
    convertedHeader: IRecord[],
    converted: IAutoConversionLineChild[],
}

export interface IAutoConversionLineChild {
    id: number,
    isNew: boolean,
    records: Record[],
}

export interface IAutoConversionResult {
    lineId: number,
    description: string,
    convertedId: number,
}

export type LineConversionChild = {
    area: string,
    controller: string,
    action: string,
    id: string,
    convertToController: string,
    conversionData: ILineConversionData,
}

export interface IStandaloneConversionInfo {
    title: string,
    documentInfo: IDocument,
    convertToRouteInfo: IRoute,
    fieldInfo: IDocument,
}

// START DOCUMENT REVAMP
export type ItemTablePicker = {
    property: IProperty,
    independent?: number,
    independents?: number[], 
}

export interface ISelectedPicker {
    id: number,
    property: IProperty,
}

/// Holds information needed to process generic components, <see cref="Schema"/> and <see cref="Data"/> are 
/// maps that will be used to present the data.
export interface IDocument {
    schema: IProperty[],
    data: IData[],
    totalCount: number,
}

/// A collection of information used to display a row of data,
/// <see cref="Records"/> are properties that are described on the model while
/// outer properties such as <see cref="PeriodStart"/> and <see cref="PeriodEnd"/>
/// are shadow properties that are not found on the model.
export interface IData {
    records: IRecord[],
    periodStart: string,
    periodEnd: string,
}

/// Information used to display a record.
export interface IRecord {
    propertyName: string,
    displayValue: string,
    value: any,
    isEnabled: boolean,
}

/// Represents data about a singular property, contains information that will allow the support
/// of multiple components throughout the application.
export interface IProperty {
    propertyName: string,
    displayName: string,
    isKey: boolean,
    type: DataType,
    canUpdate: boolean,
    maxLength: number,
    isRequired: boolean,
    displayOrder: number,
    displayType: FieldDisplayType,
    dependentRelationship: IDependentRelationship,
    dependentRelationships: IDependentRelationship[],
    fetchUri: string,
    staticChoices: IChoice[],
    currencyProperty: string,
    navigationType: DataType,
    navigationProperty: string,
    navigationDisplayProperty: string,
}

/// A record describing that the current <see cref="Property"/> is dependent on a
/// another. <see cref="PrependDisplay"/> will be used to determine if data should be 
/// prepended to an input such as currency code.
export interface IDependentRelationship {
    propertyName: string,
    prependDisplay: boolean,
    appendDisplay: boolean,
    displayOrder: number,
}

/// Used to construct a simple dropdown.
export interface IChoice {
    fieldName: string,
    displayName: string,
    fieldValue: string,
}

/// Determines what an input should be rendered as such as.
export enum FieldDisplayType {
    None = 0,
    TextArea,
    Span,
    Picker,
}

export interface IDisplayInfo {
    actions: IRoute[],
    childTable: IRoute
}

export interface IValidRoute {
    identifier: string,
    area: string,
    controller: string,
    display?: string,
    pluralDisplay?: string,
    childArea?: string,
    children?: IValidRouteChild[],
}

interface IValidRouteChild {
    identifier: string,
    controller: string,
    displayName: string,
}

export interface IRoute {
    area: string,
    controller: string,
    action: string,
    displayName: string,
    id: number,
    imgUrl: string,
    indexOnly: boolean,
}

interface IBaseInfo {
    actions: IRoute[],
    childDisplayTable?: IRoute,
    childDisplayTables?: IRoute[],
    specialIdentifier?: string,
    specialActions?: ActionType, 
}

export interface ILineConversionData {
    title: string,
    parentDescription: string,
    buttonText: string,
    convertToArea: string,
    convertToController: string,
    info: IDocument,
    standalone: IDocument,
}

// END DOCUMENT REVAMP

export interface DocumentDropdown {
    display: string,
    value: string,
}

export interface IConversionInfo {
    info: IConversionCardInfo,
    next: IConversionCardInfo,
    up: IConversionCardInfo[],
    down: IConversionCardInfo[],
}

export interface IConversionCardInfo {
    displayName: string,
    area: string,
    controller: string,
    identifier: string,
    action: string,
    data: IConversionCardRecord[],
    details: any,
}

export interface IConversionCardRecord {
    documentId: number,
    documentDescription: string,
    lineId: number,
    lineDescription: string,
}

export interface ITableInfo {
    documentId: string,
    area: string,
    displayName: string,
    controller: string,
    lineArea: string,
    action: string,
    defaultSort: string,
    defaultFilter: string,
}

export interface FilterInfo {
    displayName: string,
    dataType: string,
    fieldOverride: string,
    prefix: string,
    propertyName: string,
    inline: boolean,
    isDefault: boolean,
}

export interface CustomerDefinition {
    id: number,
    dataType: string,
    column: string,
    table: string,
}

export interface CustDefinitionJson {
    id: number,
    dataType: string,
    column: string,
    localizedName: string,
    value: string,
    span: string,
}

export interface DefinitionInfo {
    itemValue: number,
    text: string,
}

export enum ItemTableDisplay {
    Header,
    Line,
    Related,
}

export enum DataType {
    Int = 0,
    Boolean,
    Decimal,
    String,
    DateTime,
    Date,
    Guid,

    /* Legacy Fields */

    Dropdown,
    Money,
    Textarea,
    Currency,
    Tax,
}

export interface ActionData {
    url: string,
    imageSrc: string,
    name: string,
}

export enum ItemType {
    Display,
    Form,
}

