import axios from "axios";
import React from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DataContext } from "../App";
import Loading from "../components/Loading";
import SomethingWentWrong from "../components/SomethingWentWrong";
import { DataType, IDocument, IRecord, IStandaloneConversionInfo } from "../interfaces.d";
import ItemForm from "../item/ItemForm";

type StandaloneConversionParams = {
    id: string,
    area: string,
    controller: string,
    action?: string,
    independentId?: string,
    childIdentifier?: string,
}

function StandaloneConversion() {
    const { id, area, controller, action, independentId, childIdentifier } = useParams<StandaloneConversionParams>();
    const [initialError, setInitialError] = React.useState<boolean>();
    const [error, setError] = React.useState<string>();
    const [standaloneInfo, setStandaloneInfo] = React.useState<IStandaloneConversionInfo>();
    const data = React.useContext(DataContext).find(vr => vr.identifier === controller);
    const apiController = childIdentifier ? data.children.find(c => c.identifier === childIdentifier).controller : data.controller;
    const documentName = childIdentifier ?
        (data.children.find(c => c.identifier === childIdentifier).displayName ?? data.children.find(c => c.identifier === childIdentifier).controller) :
        (data.display ?? data.controller);

    const navigate = useNavigate();

    React.useEffect(() => {
        // For the time being, if action is null, we will default to Copy functionality.
        if (action) {
            axios.get<IStandaloneConversionInfo>(`/api/${apiController}/Get${action ?? "Copy"}Data/${id}`)
                .then(response => {
                    setStandaloneInfo(response.data)
                })
                .catch(_err => setInitialError(true))
        } else {
            axios.get<IDocument>(`/api/${apiController}/${id}`)
                .then(response => {
                    setStandaloneInfo({ documentInfo: response.data } as IStandaloneConversionInfo);
                })
                .catch(_err => setInitialError(true))
        }
    }, [apiController, id, action])

    const invokeConversion = (values: any) => {
        setError('');
        const redirect = !standaloneInfo.fieldInfo ? `/${area}/${controller}/Item/`
            : `/${standaloneInfo.convertToRouteInfo?.area ?? area}/${standaloneInfo.convertToRouteInfo.controller}/Item/`;

        axios.post(`/api/${apiController}/${action ?? "Copy"}/${id}`, values)
            .then(response => {
                navigate(redirect + response.data);
            })
            .catch(e => {
                if (e.response) {
                    setError(e.response.data.title ? e.response.data.title : e.response.data);
                }
            });
    }

    const handleFormSubmit = (e: any, values: any) => {
        e.preventDefault();
        invokeConversion(values);
    }

    const getForm = () => {
        if (!standaloneInfo) return;

        if (!standaloneInfo.fieldInfo) {
            return <Form onSubmit={(e) => handleFormSubmit(e, undefined)}>
                <Alert variant="light">Would you like to create a copy of the current document?</Alert>
                <Button type="submit">Copy</Button>
            </Form>
        } else {
            return <ItemForm documentData={standaloneInfo.fieldInfo} ajaxCallback={invokeConversion} documentName={undefined} changeType={undefined} actionDisplayName={undefined} />;
        }
    }

    const getDetailedList = () => {
        if (!standaloneInfo) return;

        return <dl className="row">
            {
                standaloneInfo.documentInfo.schema.filter(p => p.displayOrder !== -1 && !p.isKey).map(property => {
                    let record: IRecord = standaloneInfo?.documentInfo.data[0].records.find(r => r.propertyName === property.propertyName)

                    const getDisplayValue = () => {
                        switch (property.type) {
                            case DataType.Date:
                                return <>{new Date(record.displayValue ?? record.value).toLocaleDateString()} &nbsp;</>
                            case DataType.DateTime:
                                return <>{new Date(record.displayValue ?? record.value).toLocaleString()} &nbsp;</>
                            case DataType.Boolean:
                                return <input onClick={(e) => e.preventDefault()} readOnly checked={record.value} type="checkbox" aria-label="Checkbox for following text input" />
                            default:
                                return <>{record.displayValue ?? record.value} &nbsp;</>
                        }
                    }

                    return <React.Fragment key={property.propertyName}>
                        <dt className="col-sm-2">{property.displayName ?? property.propertyName}</dt>
                        <dd className={`col-sm-10 ${property.type === DataType.Textarea ? 'notes' : ''}`}>{getDisplayValue()}</dd>
                    </React.Fragment>
                })
            }
        </dl>
    }

    return (
        initialError ? <SomethingWentWrong /> :
        !standaloneInfo ? <Loading /> :
            <>
                {error && <div className="form-error">&bull; {error}</div>}
                <h2>{standaloneInfo?.title ?? "Copy"}</h2>
                <h4>{documentName}</h4>

                {getDetailedList()}
                {getForm()}

                <div className="mt-2">
                    <Link to={`/${area}/${controller}${independentId ? `/Item/${independentId}` : ''}`}><img alt="Back" className="table-img cursor-pointer mb-2" title="Back" src="/images/glyphicons-basic-223-chevron-left.svg" /></Link>
                </div>
            </>
    );
}

export default StandaloneConversion;