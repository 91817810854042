import * as React from "react";
import DatePicker from "react-datepicker";
import { Button, InputGroup, Modal } from "react-bootstrap";
import { DataType, FieldDisplayType, IProperty, IRecord, ISelectedPicker } from "../interfaces.d";
import ItemTable from "./ItemTable";
import { getLocalizedDate } from "../helpers";
import axios from "axios";

type ItemPickerType = {
    schema: IProperty[],
    property: IProperty,
    record?: IRecord,
    values: any,
    selectedPicker: { property: IProperty, id: number },
    setSelectedPicker(picker: ISelectedPicker),
    setFieldValue(prop: string, value: string | number | Date | null): void,
    appendElement?: React.Component | HTMLElement | string,
    disabled?: boolean,
}

function ItemPicker({ schema, property, record, values, selectedPicker, setSelectedPicker, setFieldValue, appendElement, disabled }: ItemPickerType) {
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const [showEraser, setShowEraser] = React.useState<boolean>(false);
    const [spanDisplay, setSpanDisplay] = React.useState<string | null>();

    React.useEffect(() => {
        if (!property.isRequired && values[property.propertyName]) setShowEraser(true);
        if (record) setSpanDisplay(record.displayValue ?? record.value);    
    }, [property, record, values])

    React.useEffect(() => {
        if (selectedPicker) {        
            const dependsOnCurrent = (property.dependentRelationship?.propertyName === selectedPicker.property.propertyName ||
                property.dependentRelationships?.find(dp => dp.propertyName === selectedPicker.property.propertyName) !== undefined);

            if (dependsOnCurrent) {
                setSpanDisplay('');
                setFieldValue(property.propertyName, null);
                setShowEraser(false);

                const npDependents = schema.filter(p => p.displayType !== FieldDisplayType.Picker && p.dependentRelationship?.propertyName === property.propertyName);
                npDependents?.forEach(dp => setFieldValue(dp.propertyName, ''));
            }
        }
    }, [selectedPicker, property, schema, setFieldValue]);

    function selectRecordCallback(id: number, spanRecord: IRecord) {
        if (record) record.displayValue = spanRecord.displayValue;
        setSpanDisplay(spanRecord.displayValue);

        setFieldValue(property.propertyName, id);

        // Dependents
        const selectedPicker: ISelectedPicker = { property: property, id: id };
        setSelectedPicker(selectedPicker);

        /* 
         * Check to see if there are any non-picker dependents, at the moment this will only be
         * the Contract field on Labor Times.
        */

        const npDependents = schema.filter(p => p.displayType !== FieldDisplayType.Picker && p.dependentRelationship?.propertyName === selectedPicker.property.propertyName);
        npDependents?.forEach(dp => {
            axios.get(`${dp.fetchUri}?${dp.dependentRelationship.propertyName}=${selectedPicker.id}`)
                .then(response => setFieldValue(dp.propertyName, response.data))
        });

        if (!property.isRequired) {
            setShowEraser(true);
        }

        setShowModal(false);
    }

    function handleEraserClick() {
        setSpanDisplay(null);
        setShowEraser(false);

        // Used to update validation
        setFieldValue(property.propertyName, null);
    }

    function getBody() {
        // Logic to support emulating the appearance of appending a react-datepicker to the current ItemPicker. May be expanded on
        // at another point to expand other "unique" components/elements.
        if (typeof appendElement === "string") {
            const appendProperty = schema.find(p => p.propertyName === appendElement);
            if (!appendProperty) return;

            switch (appendProperty.type) {
                case DataType.Date:
                case DataType.DateTime:
                    const CustomDateTime = React.forwardRef(({ _value, onClick }: any, ref: any) => {
                        const appendProperty = schema.find(p => p.propertyName === appendElement);

                        return <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>{property.displayName ?? property.propertyName}</InputGroup.Text>
                            </InputGroup.Prepend>

                            <InputGroup.Prepend>
                                <InputGroup.Text className="cursor-pointer" onClick={() => setShowModal(true)}>{spanDisplay ?? ''}</InputGroup.Text>
                            </InputGroup.Prepend>

                            {appendProperty && <span className="form-control border-right-0 cursor-pointer" ref={ref} onClick={onClick}>{values[appendProperty.propertyName] ? getLocalizedDate(values[appendProperty.propertyName], appendProperty.type) : ''}</span>}

                            <InputGroup.Append className="cursor-pointer" onClick={onClick}>
                                <InputGroup.Text style={{ backgroundColor: "transparent" }}>
                                    <img alt="Calendar" className="table-img" src="/images/glyphicons-basic-46-calendar.svg" />
                                </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>;
                    })

                    return <DatePicker
                        selected={values[appendProperty.propertyName] ? new Date(values[appendProperty.propertyName].toLocaleString()) : new Date()}
                        showTimeSelect={appendProperty.type === DataType.DateTime}
                        customInput={<CustomDateTime />}
                        id={appendProperty.propertyName}
                        name={appendProperty.propertyName}
                        onChange={e => {
                            if (e instanceof Date) {
                                setFieldValue(appendProperty.propertyName, e)
                            }
                        }
                        }
                    />;
                default:
                    return;
            }
        }
        else return <InputGroup>
            <InputGroup.Prepend>
                <InputGroup.Text>{property.displayName ?? property.propertyName}</InputGroup.Text>
                <InputGroup.Text className={!disabled ? 'cursor-pointer' : ''}>
                    <img alt="Search" onClick={() => !disabled && setShowModal(true)} className="table-img" src="/images/glyphicons-basic-28-search.svg" />
                </InputGroup.Text>
            </InputGroup.Prepend>
            <span data-testid={property.propertyName + '-picker-span'} onClick={() => !disabled && setShowModal(true)} className={`form-control ${!disabled ? 'cursor-pointer' : 'disabled'}`}>{spanDisplay ?? ''}</span>

            {
                appendElement &&
                <InputGroup.Append>
                    {appendElement}
                </InputGroup.Append>
            }

            {
                showEraser &&
                <InputGroup.Append>
                    <InputGroup.Text><img alt="Eraser" onClick={() => handleEraserClick()} className="table-img cursor-pointer" src="/images/glyphicons-basic-250-eraser.svg" /></InputGroup.Text>
                </InputGroup.Append>
            }
        </InputGroup>;
    }

    return (<>
        <Modal size="lg" id={property.propertyName + "PickerModal"} show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{property.displayName ?? property.propertyName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ItemTable pickerInfo={property} selectRecordCallback={selectRecordCallback} values={values} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>

        {getBody()}
    </>);
}

export default ItemPicker;
