import axios from "axios";
import React from "react";
import ItemTable from "./ItemTable";
import { IBaseInfo, IRoute, IValidRoute } from "../interfaces";
import { useParams, Link } from "react-router-dom";
import Loading from "../components/Loading";
import { DataContext } from "../App";
import { Fab } from "@mui/material";

type IndexParams = {
    area: string,
    controller: string,
}

interface IItemIndex {
    info?: IRoute,
    independentId?: number,
}

function ItemIndex({ info, independentId }: IItemIndex) {
    const [baseInfo, setBaseInfo] = React.useState<IBaseInfo>();
    const { controller } = useParams<IndexParams>();
    const data = React.useContext(DataContext).find(vr => vr.identifier === controller);
    if (!info) document.title = `${data.pluralDisplay ?? data.display ?? ''} - Decagon`;

    React.useEffect(() => {
        axios.get<IBaseInfo>(`/api/${info?.controller ?? data.controller}/Info`)
            .then(response => setBaseInfo(response.data));

        return () => {
            setBaseInfo(null);
        }
    }, [controller, data, info]);

    const getIndexActions = () => {
        return baseInfo && baseInfo.actions?.filter(a => a.indexOnly).map(action =>
            <Link
                data-testid='index-action'
                key={action.action}
                to={`/${action.area}/${data.identifier}/${action.action}${independentId ? `/${independentId}/${baseInfo.specialIdentifier}` : ''}`}
                title={action.displayName}>

                    <Fab sx={{ bgcolor: "white", mr: 1.5 }}  aria-label="create">
                        <img alt={action.displayName ?? action.action} className="index-action" src={action.imgUrl} />

                    </Fab>
                </Link>);
    }

    return (!baseInfo ? <Loading /> :
        <>
            {
                info ? <h4>{info.displayName ?? info.controller}</h4> :
                    <h2>{data.pluralDisplay ?? data.display}</h2>
            }

            <div className="mb-2">
                {getIndexActions()}
            </div>

            <ItemTable specialIdentifier={baseInfo?.specialIdentifier} routeInfo={info ? { area: info.area, controller: info.controller, identifier: controller } as IValidRoute : data} independentId={independentId} />
        </>
    );
}

export default ItemIndex;