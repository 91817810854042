import axios from "axios";
import React from "react";
import { Spinner } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DataContext } from "../App";
import Loading from "../components/Loading";
import SomethingWentWrong from "../components/SomethingWentWrong";
import { IAutoConversion, IAutoConversionLine, IAutoConversionLineChild, IAutoConversionResult, IRecord } from "../interfaces";

type AutoConversionParams = {
    area: string,
    identifier: string,
    action: string,
}

function AutoConversion() {
    const { area, identifier, action } = useParams<AutoConversionParams>();
    const [initialError, setInitialError] = React.useState<boolean>(false);
    const [data, setData] = React.useState<IAutoConversion>();
    const [multipleResults, setMultipleResults] = React.useState<IAutoConversionResult[]>([]);
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
    const [selectedLines, setSelectedLines] = React.useState<number[]>([]);
    const [serverError, setServerError] = React.useState<string>();
    const dataContext = React.useContext(DataContext);
    const controller = dataContext.find(dc => dc.identifier === identifier)?.controller;
    const navigate = useNavigate();

    React.useEffect(() => {
        axios.get<IAutoConversion>(`/api/${controller}/Get${action}Data`)
            .then(response => setData(response.data))
            .catch(_err => setInitialError(false))
    }, [controller, action])

    // Instead of just navigating to the destination index, let's provide a component.
    const MultipleResult = () => {
        return (<div>
            <h2>{data.title ?? controller}</h2>
            <div className="alert alert-light">Multiple documents were successfully converted, refer to the results below.</div>

            {
                data.lines.filter(l => multipleResults.find(mr => mr.lineId === l.id)).map((line, index) => {
                    const resultRecord = multipleResults.find(r => r.lineId === line.id);

                    return (<Link key={index} to={`/${data.convertToArea}/${data.convertToIdentifier}/Item/${resultRecord.convertedId}`} className="block-link border rounded p-2 mb-2">
                        <div className="text-left">
                            {line.isNew && <div className="badge badge-pill badge-primary ml-1">New</div>}
                            {data.parentDisplayName && <h5 className="inline mt-1 ml-1">{data.parentDisplayName}</h5>}
                        </div>

                        {getParentInfo(line.parent, resultRecord)}

                        <div className="border rounded p-2">
                            {getChildInfo(line.children)}
                        </div>

                        <div className="border rounded p-2 mt-2">
                            {getConvertedInfo(line)}
                        </div>
                    </Link>)
                })
            }
        </div>)
    }

    function getParentInfo(records: IRecord[], resultRecord?: IAutoConversionResult) {
        return <table className="table-sm">
            <thead>
                <tr>
                    {
                        data.parentSchema.filter(s => !s.isKey).map(s => <th key={s.propertyName}>{s.displayName ?? s.propertyName}</th>)
                    }
                </tr>
            </thead>
            <tbody>
                <tr>
                    {
                        data.parentSchema.filter(s => !s.isKey).map((s, index) => {
                            const record = records.find(r => r.propertyName === s.propertyName);

                            return <td key={index}>
                                {(index === 0 && resultRecord) ? resultRecord.description : (record.displayValue ?? record.value)}
                            </td>
                        })
                    }
                </tr>
            </tbody>
        </table>
    }

    function getChildInfo(lines: IAutoConversionLineChild[]) {
        return <div className="table-responsive">
            <table className="table-sm w-100">
                <thead>
                    <tr>
                        <th></th>
                        {
                            data.childSchema.filter(s => !s.isKey).map((s, index) => <th key={index}>{s.displayName ?? s.propertyName}</th>)
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        lines.map((line, index) => {
                            return <tr key={index} className={line.isNew ? "table-primary" : ""}>
                                <th>{line.isNew && <div className="badge badge-pill badge-primary ml-1">New</div>}</th>
                                {
                                    line.records.filter(r => r.propertyName !== data.childSchema.find(p => p.isKey)?.propertyName).map(r => <td key={r.propertyName}>{r.displayValue ?? r.value}</td>)
                                }
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
    }

    function getConvertedInfo(line: IAutoConversionLine) {
        return <div className="table-responsive">
            <h5>Converted {data.convertedDisplayName}</h5>

            {
                line.convertedHeader?.length > 0 && <table className="table-sm">
                    <thead>
                        <tr>
                            {
                                line.convertedHeader.map(ch => <th key={ch.propertyName}>{ch.propertyName}</th>)
                            }
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {
                                line.convertedHeader.map(ch => <td key={ch.propertyName}>{ch.value}</td>)
                            }
                        </tr>
                    </tbody>
                </table>
            }

            <table className={`table-sm w-100 ${multipleResults.length === 0 ? 'table-hover' : ''}`}>
                <thead>
                    <tr>
                        <th></th>
                        {
                            data.convertedSchema.filter(s => !s.isKey).map(s => <th key={s.propertyName}>{s.displayName ?? s.propertyName}</th>)
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        line.converted.map((line, index) => {
                            return <tr key={index} className={`index-row cursor-pointer ${line.isNew ? "table-primary" : ""}`}>
                                <td>
                                    {
                                        multipleResults.length > 0 ? <div className="badge badge-pill badge-primary ml-1">New</div> :
                                            <Link to={`/${area}/${identifier}/Item/${line.id}`}>{line.isNew && <div className="badge badge-pill badge-primary ml-1">New</div>}</Link>
                                    }
                                </td>
                                {
                                    line.records.filter(r => r.propertyName !== data.childSchema.find(p => p.isKey)?.propertyName).map(r => <td key={r.propertyName}>{multipleResults.length > 0 ? (r.displayValue ?? r.value) : <Link to={`/${area}/${identifier}/Item/${line.id}`}>{r.displayValue ?? r.value}</Link>}</td>)
                                }
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
    }

    function handleCheckbox(e: React.ChangeEvent<HTMLInputElement>, id: number) {
        let copy = [...selectedLines];

        if (e.currentTarget.checked && selectedLines.indexOf(id) === -1) {
            copy.push(id);
        }
        else {
            copy = copy.filter(c => c !== id);
        }

        setSelectedLines(copy);
    }

    function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        setServerError('');
        setIsSubmitting(true);

        axios.post<IAutoConversionResult[]>(`/api/${controller}/${action}`, selectedLines)
            .then(response => {
                if (selectedLines.length === 0 || selectedLines.length > 1) {
                    setMultipleResults(response.data);
                    setIsSubmitting(false);
                } else navigate(`/${data.convertToArea}/${data.convertToIdentifier}${`/Item/${response.data[0].convertedId}`}`);
            })
            .catch(e => {
                if (e.response) {
                    setServerError(e.response.data.title ? e.response.data.title : e.response.data)
                }

                setIsSubmitting(false);
            })
    }

    return (initialError ? <SomethingWentWrong /> : !data ? <div className="text-center">
        <Loading />
            <div className="alert alert-light">You may experience longer wait times loading this action.</div>
        </div> : multipleResults.length > 0 ? <MultipleResult /> :
        <>
            <h2>{data.title ?? controller}</h2>

            {serverError && <div className="form-error">&bull; {serverError}</div>}

            <form onSubmit={handleSubmit}>
                <button disabled={isSubmitting} type="submit" className="btn btn-primary mb-2">
                    Convert {selectedLines.length > 0 ? 'Selected' : 'All'}
                    {isSubmitting && <Spinner animation="border" className="ml-2" size="sm" color="white" />}
                </button>

                {
                        data.lines.map((line, index) => <div key={index} className="border rounded p-2 mb-2">
                        <div className="text-left">
                            <input checked={selectedLines.indexOf(line.id) !== -1} onChange={(e) => handleCheckbox(e, line.id)} type="checkbox" />
                            {line.isNew && <div className="badge badge-pill badge-primary ml-1">New</div>}
                            {data.parentDisplayName && <h5 className="inline mt-1 ml-1">{data.parentDisplayName}</h5>}
                        </div>

                        {getParentInfo(line.parent)}

                        <div className="border rounded p-2">
                            {getChildInfo(line.children)}
                        </div>

                        <div className="border rounded p-2 mt-2">
                            {getConvertedInfo(line)}
                        </div>
                    </div>)
                }

                <button disabled={isSubmitting} type="submit" className="btn btn-primary mt-2">
                    Convert {selectedLines.length > 0 ? 'Selected' : 'All'}
                    {isSubmitting && <Spinner animation="border" className="ml-2" size="sm" color="white" />}
                </button>
            </form>
        </>)
}

export default AutoConversion