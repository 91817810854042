import * as React from 'react';
import MenuIcon from "@mui/icons-material/Menu";
import axios from "axios";
import { AppBar, Box, Toolbar, IconButton, Typography, Menu, Container, Drawer, Avatar, Button, Tooltip, MenuItem, List, ListItem, ListItemText, Divider, Fab } from "@mui/material";
import { DataContext } from "../App";
import { Link } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { graphRequest } from "../authConfig";
import { ArrowBack } from '@mui/icons-material';

const categories = ['Core', 'Sales', 'Purchasing', 'Warehousing'];

function stringToColor(str: string) {
    let hash = 0;

    /* eslint-disable no-bitwise */
    for (let i = 0; i < str.length; i += 1) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (let i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += value.toString(16).padStart(2, '0')
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name: string) {
    if (!name) return

    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

const SiteNavBar = () => {
    const { instance, accounts } = useMsal();
    const dataContext = React.useContext(DataContext);
    const isAuthenticated = useIsAuthenticated();
    const name = accounts[0] && accounts[0].name;
    const [showCategoryDrawer, setShowCategoryDrawer] = React.useState<boolean>(false);
    const [anchorElCore, setAnchorElCore] = React.useState<HTMLElement>();
    const [anchorElUser, setAnchorElUser] = React.useState<HTMLElement>();
    const [anchorElSales, setAnchorElSales] = React.useState<HTMLElement>();
    const [anchorElPurchasing, setAnchorElPurchasing] = React.useState<HTMLElement>(null);
    const [anchorElWarehousing, setAnchorElWarehousing] = React.useState<HTMLElement>(null);
    const [profilePhoto, setProfilePhoto] = React.useState<any>();

    const [mobileCategory, setMobileCategory] = React.useState<string>();

    React.useMemo(() => {
        const tokenReq = {
            ...graphRequest,
            account: accounts[0]
        };

        isAuthenticated && instance.acquireTokenSilent(tokenReq)
            .then(response => {
                const bearer = "Bearer " + response.accessToken;
                const graphEndpoint = "https://graph.microsoft.com/v1.0/me/photo/$value";

                axios.get(graphEndpoint, { headers: { "Authorization": bearer }, responseType: "blob" })
                    .then(response => {
                        var reader = new FileReader();
                        reader.readAsDataURL(response.data);

                        reader.onloadend = function () {
                            setProfilePhoto(reader.result);
                        }
                    });
            });
    }, [instance, accounts, isAuthenticated])

    const handleDrawerClose = () => {
        setShowCategoryDrawer(false);
        setMobileCategory(null);
    }

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleOpenCoreMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElCore(event.currentTarget);
    };

    const handleOpenSalesMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElSales(event.currentTarget);
    };

    const handleOpenPurchasingMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElPurchasing(event.currentTarget);
    };

    const handleOpenWarehousingMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElWarehousing(event.currentTarget);
    };

    const handleCloseCoreMenu = () => {
        setAnchorElCore(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleCloseSalesMenu = () => {
        setAnchorElSales(null);
    };

    const handleClosePurchasingMenu = () => {
        setAnchorElPurchasing(null);
    };

    const handleCloseWarehousingMenu = () => {
        setAnchorElWarehousing(null);
    };

    function getFilterCriteria(category: string) : string[] {
        const areas: string[] = [];

        switch (category) {
            case 'Core':
                areas.push('Core', 'Accounting');
                break;
            case 'Sales':
                areas.push('Customer');
                break;
            case 'Purchasing':
                areas.push('Vendor');
                break;
            default:
                areas.push('Inventory');
                break;
        }

        return areas;
    }

    function getMenu(category: string) {
        let handleCloseCatMenu;
        let anchorEl;

        switch (category) {
            case 'Core':
                handleCloseCatMenu = handleCloseCoreMenu;
                anchorEl = anchorElCore;
                break;
            case 'Sales':
                handleCloseCatMenu = handleCloseSalesMenu;
                anchorEl = anchorElSales;
                break;
            case 'Purchasing':
                handleCloseCatMenu = handleClosePurchasingMenu;
                anchorEl = anchorElPurchasing;
                break;
            default:
                handleCloseCatMenu = handleCloseWarehousingMenu;
                anchorEl = anchorElWarehousing;
                break;
        }

        return <Menu
            autoFocus={false}
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleCloseCatMenu}
        >
            {dataContext.filter(dc => getFilterCriteria(category).indexOf(dc.area) !== -1)
                .map((vr, index) => <Link key={index} onClick={handleCloseCatMenu} className="navbar-link" to={`/${vr.area}/${vr.identifier}`}>
                    <MenuItem dense key={vr.identifier}>
                        <Typography textAlign="center">
                            {vr.pluralDisplay ?? vr.controller}
                        </Typography>
                    </MenuItem></Link>)}
        </Menu>
    }

    return (
        <AppBar className="no-print" position="static">
            <Container sx={{ bgcolor: '#f5f4f4' }} maxWidth="xl">
                <Toolbar disableGutters>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
                    >
                        <Link to="/">
                            <img alt="Decagon Logo" src="/images/Logo.svg" className="mw-30 mh-30" />
                        </Link>
                    </Typography>

                    {isAuthenticated && <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="show categories"
                            aria-controls="menu-icon"
                            aria-haspopup="true"
                            onClick={() => setShowCategoryDrawer(true)}
                        >
                            <MenuIcon />
                        </IconButton>

                        <Drawer
                            anchor="left"
                            open={showCategoryDrawer}
                            onClose={handleDrawerClose}
                        >
                            <Box sx={{ width: 200 }}>
                                <List>
                                    {
                                    !mobileCategory ? categories.map(category =>
                                            <ListItem
                                                key={category}
                                                onClick={(e) => setMobileCategory(category)}
                                            >
                                                <ListItemText className="cursor-pointer" primary={category} />
                                            </ListItem>) : <>
                                                <ListItem>{mobileCategory}</ListItem>
                                                <Divider />

                                                {dataContext.filter(dc => getFilterCriteria(mobileCategory).indexOf(dc.area) !== -1)
                                                    .map((vr, index) => <Link key={index} onClick={handleDrawerClose} className="navbar-link" to={`/${vr.area}/${vr.identifier}`}>
                                                        <ListItem dense key={vr.identifier}>
                                                            <ListItemText primary={vr.pluralDisplay ?? vr.controller} />
                                                        </ListItem></Link>)}

                                                <div className="tx-center mt-1em">
                                                    <Fab sx={{ bgcolor: 'white' }} onClick={() => setMobileCategory(null)} size="small" aria-label="back">
                                                        <ArrowBack />
                                                    </Fab>
                                                </div>
                                            </>
                                    }
                                </List>
                            </Box>
                        </Drawer>
                    </Box>}

                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
                    >
                        <Link to="/">
                            <img alt="Decagon Logo" src="/images/Logo.svg" className="mw-30 mh-30" />
                        </Link>
                    </Typography>

                    {isAuthenticated && <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {
                            categories.map(category => <React.Fragment key={category}>
                                <Button
                                    onClick={(e) => {
                                        switch (category) {
                                            case 'Core':
                                                handleOpenCoreMenu(e)
                                                break;
                                            case 'Sales':
                                                handleOpenSalesMenu(e)
                                                break;
                                            case 'Purchasing':
                                                handleOpenPurchasingMenu(e)
                                                break;
                                            default:
                                                handleOpenWarehousingMenu(e)
                                        }
                                    }}
                                    sx={{ my: 2, color: '#122447', display: 'block' }}
                                >
                                    {category}
                                </Button>

                                {getMenu(category)}
                            </React.Fragment>)
                        }
                    </Box>}

                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open user settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar src={profilePhoto ?? ''} alt="Profile Photo" {...stringAvatar(name)} />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <MenuItem onClick={() => instance.logout()}>
                                <Typography textAlign="center">Logout</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default SiteNavBar;