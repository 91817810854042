import * as React from "react";

function About() {
    React.useEffect(() => {
        document.title = "About - Decagon";
    }, []);

    return <>
        <h2>About</h2>

        <dl>
            <dt>Current Environment</dt>
            <dd></dd>
            <dt>Process Architecture</dt>
            <dd></dd>
            <dt>Framework</dt>
            <dd></dd>
            <dt>OS Description</dt>
            <dd></dd>
            <dt>Is Administrator</dt>
            <dd></dd>
            <dt>Is Requester</dt>
            <dd></dd>
        </dl>
    </>
}

export default About;