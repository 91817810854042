import * as React from "react";
import { IProperty, DataType } from "../interfaces.d";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { FilterContext } from "../item/ItemTable";

interface IFiltering {
    filter: string,
    setFilter(filter: string): void,
    properties: IProperty[],
}

interface IFilterInfo {
    column: string,
    navigationDisplayProperty: string,
    criteria1: string,
    criteria2: string,
    operand: string,
    type: DataType,
}

function Filtering({ filter, setFilter, properties }: IFiltering): JSX.Element {
    const [filterInfo, setFilterInfo] = React.useState<IFilterInfo[]>([]);

    // Whenever filterInfo is updated, we must re-build the filter
    React.useEffect(() => {
        if (filterInfo?.length > 0) {
            let filterString: string = '';

            filterInfo.forEach((fi, index) => {
                let currentFilter: string = '';

                switch (fi.type) {
                    case DataType.Date:
                    case DataType.DateTime:
                        if (fi.operand === "between") {
                            currentFilter = `${fi.column} ge cast(${fi.criteria1}) and ${fi.column} le cast(${fi.criteria2})${filterInfo.length === (index + 1) ? "" : " and "}`;
                        } else {
                            currentFilter = `${fi.column} ${fi.operand} cast(${fi.criteria1})${filterInfo.length === (index + 1) ? "" : " and "}`;
                        }
                        break
                    case DataType.Dropdown:
                    case DataType.String:
                        if (fi.operand === "like") currentFilter = `contains(${fi.column}${fi.navigationDisplayProperty ? `/${fi.navigationDisplayProperty}` : ''},'${fi.criteria1}')${filterInfo.length === (index + 1) ? "" : " and "}`;
                        else currentFilter = `${fi.column}${fi.navigationDisplayProperty ? `/${fi.navigationDisplayProperty}` : ''} ${fi.operand} '${fi.criteria1}'${filterInfo.length === (index + 1) ? "" : " and "}`;
                        break
                    case DataType.Boolean:
                        currentFilter = `${fi.column}${fi.navigationDisplayProperty ? `/${fi.navigationDisplayProperty}` : ''} eq ${fi.criteria1}${filterInfo.length === (index + 1) ? "" : " and "}`
                        break
                    default:
                        if (fi.operand === "like") currentFilter = `contains(${fi.column}${fi.navigationDisplayProperty ? `/${fi.navigationDisplayProperty}` : ''},'${fi.criteria1}')${filterInfo.length === (index + 1) ? "" : " and "}`
                        else currentFilter = `${fi.column}${fi.navigationDisplayProperty ? `/${fi.navigationDisplayProperty}` : ''} ${fi.operand} ${fi.criteria1}${filterInfo.length === (index + 1) ? "" : " and "}`;
                        break
                }

                filterString += currentFilter;
            })

            setFilter(filterString);
        } else setFilter(null)

    }, [filterInfo, setFilter])

    const updateFilterInfo = (newFI: IFilterInfo[]) => setFilterInfo(newFI);

    return (
        <>
            {properties.map((property, index) =>
                <FilteringModal
                    key={index}
                    property={property}
                    filterInfo={filterInfo}
                    setFilterInfo={updateFilterInfo}
                />
            )}
        </>
    )
}

interface IFilteringModal {
    property: IProperty,
    filterInfo: IFilterInfo[],
    setFilterInfo(updatedFI: IFilterInfo[]),
}

function FilteringModal({ property, filterInfo, setFilterInfo }: IFilteringModal) {
    const operatorSelectRef = React.useRef<HTMLSelectElement | null>();
    const searchInputRef = React.useRef<HTMLInputElement | null>();
    const [dateTimeStart, setDateTimeStart] = React.useState<Date>();
    const [dateTimeEnd, setDateTimeEnd] = React.useState<Date>();
    const [selectedOperand, setSelectedOperand] = React.useState<string>();
    const [error, setError] = React.useState<string>();
    const filterData = React.useContext(FilterContext);

    const handleClose = React.useCallback(() => {
        setError('');

            const filter = filterInfo.find(fi => fi.column === property.propertyName || fi.column === property.navigationProperty);

            if (filter?.operand !== selectedOperand) setSelectedOperand(filter?.operand);

            // Handle DateTimes
            if (property.type === DataType.Date || property.type === DataType.DateTime) {
                if (dateTimeStart && filter && filter.criteria1) {
                    setDateTimeStart(new Date(filter.criteria1));
                } else {
                    setDateTimeStart(null);
                }

            if (dateTimeEnd && filter && filter.criteria2) {
                setDateTimeEnd(new Date(filter.criteria2));
            } else {
                setDateTimeEnd(null);
            }
        }
    }, [dateTimeStart, dateTimeEnd, filterInfo, property, selectedOperand])

    React.useEffect(() => {
        if (!filterData.openedModal) handleClose();
    }, [filterData.openedModal, handleClose]);

    const clear = (event: any) => {
        event.preventDefault();

        if (filterInfo.find(fi => fi.column === property.propertyName || fi.column === property.navigationProperty)) {
            const copy = [...filterInfo].filter(fi => fi.column !== property.propertyName && fi.column !== property.navigationProperty);
            setFilterInfo(copy);
        }

        if (searchInputRef.current) searchInputRef.current.value = '';
        if (dateTimeStart) setDateTimeStart(null);
        if (dateTimeEnd) setDateTimeEnd(null);
        filterData.setOpenedModal(null);
    }

    const apply = (event: any) => {
        event.preventDefault();
        setError('');

        const dataType: DataType = property.navigationProperty ? property.navigationType : property.type;

        // Error check switch
        switch (dataType) {
            case DataType.Date:
            case DataType.DateTime:
                if (!dateTimeStart || (selectedOperand === "between" && !dateTimeEnd)) {
                    setError('Date criteria required.');
                    return;
                }

                if (selectedOperand === "between" && (dateTimeStart > dateTimeEnd)) {
                    setError('Start Date must not exceed End Date.');
                    return;
                }

                break;
            case DataType.Boolean:
                break;
            default:
                if (!searchInputRef.current.value) {
                    setError('Search criteria required.');
                    return;
                }
                break;
        }

        const copy = JSON.parse(JSON.stringify([...filterInfo]));

        if (filterInfo.find(fi => fi.column === property.propertyName) || filterInfo.find(fi => fi.column === property.navigationProperty)) {
            //
            // Update existing filter record
            //

            const index = copy.findIndex(c => c.column === property.propertyName || c.column === property.navigationProperty);
            copy[index].operand = operatorSelectRef.current.value;

            switch (dataType) {
                case DataType.Date:
                case DataType.DateTime:
                    copy[index].criteria1 = dateTimeStart.toLocaleString();

                    if (selectedOperand === "between") {
                        copy[index].criteria2 = dateTimeEnd?.toLocaleString();
                    } else {
                        if (dateTimeEnd) setDateTimeEnd(null);
                        copy[index].criteria2 = null;
                    }

                    break;
                case DataType.Boolean:
                    copy[index].criteria1 = operatorSelectRef.current.value;
                    break;
                default:
                    copy[index].criteria1 = searchInputRef.current.value;
                    break;
            }
        } else {
            //
            // Create new filter record
            //

            let newFilter: IFilterInfo = {} as IFilterInfo;
            newFilter.column = property.navigationProperty ?? property.propertyName;
            newFilter.operand = operatorSelectRef.current.value;

            if (property.navigationDisplayProperty) {
                newFilter.navigationDisplayProperty = property.navigationDisplayProperty
            };

            switch (dataType) {
                case DataType.Date:
                case DataType.DateTime:
                    newFilter.criteria1 = dateTimeStart.toLocaleString();
                    newFilter.criteria2 = dateTimeEnd?.toLocaleString();
                    break;
                case DataType.Boolean:
                    newFilter.criteria1 = operatorSelectRef.current.value;
                    break;
                default:
                    newFilter.criteria1 = searchInputRef.current.value;
                    break;
            }

            newFilter.type = dataType;
            copy.push(newFilter);
        }

        setFilterInfo(copy);
        filterData.setOpenedModal(null);
    }

    const applyEnter = (event: any) => {
        if (event.key === "Enter") {
            apply(event);
        }
    }

    const getLocalizedDate = (date: Date, type: DataType): string => {
        const formattedDate = format(date, type === DataType.DateTime ? 'MM/dd/yyyy hh:mm:ss aa' : 'mm/dd/yyyy');
        return formattedDate;
    }

    const getOperatorSelect = () => {
        const dataType: DataType = property.navigationProperty ? property.navigationType : property.type;

        let body;
        switch (dataType) {
            case DataType.Date:
            case DataType.DateTime:
                body = <>
                    <option value="ge">is on or after</option>
                    <option value="gt">is after</option>
                    <option value="le">is on or before</option>
                    <option value="lt">is before</option>
                    <option value="between">is between</option>
                    <option value="eq">is</option>
                    <option value="ne">is not</option>
                </>;
                break;
            case DataType.Dropdown:
            case DataType.String:
                body = <>
                    <option value="like">like</option>
                    <option value="eq">is</option>
                    <option value="ne">is not</option>
                </>;
                break;
            case DataType.Boolean:
                body = <>
                    <option value="true">true</option>
                    <option value="false">false</option>
                </>
                break;
            default:
                body = <>
                    <option value="like">like</option>
                    <option value="eq">is</option>
                    <option value="ne">is not</option>
                    <option value="ge">greater than or equal to</option>
                    <option value="gt">greater than</option>
                    <option value="le">less than or equal to</option>
                    <option value="lt">less than</option>
                </>;
                break;
        }

        return <div className="form-group" onChange={(e: any) => setSelectedOperand(e.target.value)}>
            <label>Operator</label>
            <select value={selectedOperand} ref={operatorSelectRef} className="custom-select">
                {body}
            </select>
        </div>;
    }

    const getFilterInputs = () => {
        const dataType: DataType = property.navigationProperty ? property.navigationType : property.type;

        switch (dataType) {
            case DataType.Date:
            case DataType.DateTime:
                return <>
                    <DatePicker
                        data-testid="filter-date-input"
                        selected={dateTimeStart ?? new Date()}
                        showTimeSelect={property.type === DataType.DateTime}
                        customInput={
                            <div className="input-group mb-1">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <img src="/images/glyphicons-basic-46-calendar.svg" className="table-img" alt="Search" />
                                    </span>
                                </div>

                                <input className="form-control cursor-pointer" value={dateTimeStart ? getLocalizedDate(dateTimeStart, property.type) : ''} />
                            </div>
                        }
                        id={property.propertyName}
                        name={property.propertyName}
                        onChange={(e: any) => {
                            setDateTimeStart(e)
                        }
                        }
                    />

                    {selectedOperand === "between" && <DatePicker
                        data-testid="filter-date2-input"
                        selected={dateTimeEnd ?? new Date()}
                        showTimeSelect={property.type === DataType.DateTime}
                        customInput={
                            <div className="input-group mb-1">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <img src="/images/glyphicons-basic-46-calendar.svg" className="table-img" alt="Search" />
                                    </span>
                                </div>

                                <input className="form-control cursor-pointer" value={dateTimeEnd ? getLocalizedDate(dateTimeEnd, property.type) : ''} />
                            </div>
                        }
                        id={property.propertyName}
                        name={property.propertyName}
                        onChange={(e: any) => {
                            setDateTimeEnd(e)
                        }
                        }
                    />}
                </>;
            case DataType.Boolean:
                return
            default:
                return <div className="input-group mb-1">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id={`${property.propertyName}FilterBasicSearch`}>
                            <img src="/images/glyphicons-basic-28-search.svg" className="table-img" alt="Search" />
                        </span>
                    </div>
                    <input
                        data-testid="filter-input"
                        defaultValue={filterInfo.find(fi => fi.column === property.propertyName || fi.column === property.navigationProperty)?.criteria1}
                        ref={searchInputRef}
                        onKeyDown={applyEnter}
                        data-datatype={property.type}
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        aria-label="Search"
                        aria-describedby={property.propertyName + "FilterBasicSearch"} />
                </div>;
        }
    }

    return <Modal show={property.propertyName === filterData.openedModal} onHide={() => filterData.setOpenedModal(null)} className="modal fade" tabIndex={-1} role="dialog" aria-hidden="true" id={`${property.propertyName}FilterModal`}>
        <Modal.Header>
            <h5>{property.displayName ?? property.propertyName} Filter</h5>
            <button className="close" onClick={() => filterData.setOpenedModal(null)}>
                <span aria-hidden="true">&times;</span>
            </button>
        </Modal.Header>

        <Modal.Body>
            <div className="form-group">
                {
                    filterInfo.find(fi => fi.column === property.propertyName || fi.column === property.navigationProperty) &&
                    <img
                        onClick={clear}
                        data-datatype={property.type}
                        src="/images/glyphicons-basic-250-eraser.svg"
                        className="float-right table-img cursor-pointer"
                        alt="Clear Filter"
                    />
                }
            </div>

            {getOperatorSelect()}
            {error && <div className="form-error pb-1">&bull; {error}</div>}
            {getFilterInputs()}

            <Modal.Footer className="mt-3">
                <button type="button" className="btn btn-secondary" onClick={() => filterData.setOpenedModal(null)}>Discard Changes</button>
                <button data-testid="apply-filter" type="button" onClick={apply} data-datatype={property.type} data-prefix={property.displayName ? property.displayName.replace(/ /g, '') : property.propertyName} className="btn btn-primary">Apply</button>
            </Modal.Footer>
        </Modal.Body>
    </Modal>;
}

export default Filtering;