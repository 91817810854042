import axios from "axios";
import React from "react";
import { useParams } from "react-router-dom";
import { DataContext } from "../App";
import Loading from "../components/Loading";
import SomethingWentWrong from "../components/SomethingWentWrong";
import { PdfDocument } from "../interfaces";

type DocumentParams = {
    identifier: string,
    id: string,
}

function DocumentTotals() {
    const { identifier, id } = useParams<DocumentParams>();
    const [hasError, setHasError] = React.useState<boolean>();
    const [data, setData] = React.useState<PdfDocument>();
    const dataContext = React.useContext(DataContext);
    const apiController = dataContext.find(dc => dc.identifier === identifier)?.controller;

    React.useEffect(() => {
        axios.get<PdfDocument>(`/api/${apiController}/Document/${id}`)
            .then(response => {
                setData(response.data);
                document.title = `${response.data.document} ${response.data.description} Document - Decagon`;
            })
            .catch(_err => setHasError(true))
    }, [apiController, id])

    return (hasError ? <SomethingWentWrong /> : !data ? <Loading /> :
        <div className="century-gothic">
            <h3 className="document-h3">{data.document}</h3>

            <svg className="document-svg" preserveAspectRatio="none" x="0" y="0" width="50" height="50" viewBox="0 0 720 720">
                <defs>
                    <filter id="Filter_1" x="-20%" y="-20%" width="140%" height="140%" colorInterpolationFilters="sRGB">
                        <feColorMatrix in="SourceGraphic" type="matrix" values="0.65 0 0 0 0.07058823529411765 0 0.65 0 0 0.1411764705882353 0 0 0.65 0 0.2784313725490196 0 0 0 1 0" result="result1" />
                    </filter>
                    <g id="Gear">
                        <path fill="#000000" stroke="none" d=" M 155.75 -62.55 L 153.35 -49.7 151.8 -49.15 140.65 -53.4 128.25 -37.45 135.55 -26.65 135.25 -25.15 124 -20.45 126.5 0 139.45 2.05 140.1 3.7 135.65 15.05 151.6 27.5 162.4 19.85 164.55 20.4 169.7 31.75 189.75 28.5 191.6 15.85 192.8 15.3 204.35 19.55 216.5 3.7 209.15 -7.5 209.6 -9.05 220.75 -14.25 217.8 -34.1 205.35 -36.05 204.25 -37.25 208.75 -48.6 192.7 -60.9 182.25 -53.7 180.5 -54.15 175.6 -65.15 155.75 -62.55 M 146.1 -16.75 Q 146.1 -27.65 153.75 -35.4 161.55 -43.1 172.45 -43.1 183.35 -43.1 191.1 -35.4 198.85 -27.65 198.85 -16.75 198.85 -5.8 191.1 1.95 183.35 9.75 172.45 9.75 161.55 9.75 153.75 1.95 146.1 -5.8 146.1 -16.75 Z" />
                    </g>
                    <path id="Gear_Logo_Fill_Short_Arrow_0_Layer2_0_1_STROKES" stroke="#000000" strokeWidth="10" strokeLinejoin="round" strokeLinecap="round" fill="none" d=" M 77.9 77.95 Q 57.1 55.3 51.35 22.9 43.4 -22.4 69.8 -60.15 96.2 -97.75 141.55 -105.75 186.9 -113.75 224.55 -87.4 243.5 -74.15 254.95 -56" />
                    <path id="Gear_Logo_Fill_Short_Arrow_0_Layer2_0_2_STROKES" stroke="#000000" strokeWidth="6" strokeLinejoin="round" strokeLinecap="round" fill="none" d=" M 244.25 -47.4 L 267.95 -60.25 268.8 -28.95 244.25 -47.4 Z M 137.2 84.5 L 122.15 105.9 107.9 79.15 137.2 84.5 Z" />
                    <path id="Gear_Logo_Fill_Short_Arrow_0_Layer2_0_3_STROKES" stroke="#000000" strokeWidth="8" strokeLinejoin="round" strokeLinecap="round" fill="none" d=" M 262.2 -0.55 Q 262.55 3.8 262.55 8.3 262.55 47.6 234.7 75.4 206.9 103.25 167.55 103.25 147.5 103.25 130.45 96.05" />
                </defs>
                <g transform="matrix( 3.066314697265625, 0, 0, 3.066314697265625, -128,362.65) ">
                    <g transform="matrix( 1, 0, 0, 1, 0,0) ">
                        <use filter="url(#Filter_1)" xlinkHref="#Gear_Logo_Fill_Short_Arrow_0_Layer2_0_1_STROKES" />
                        <use filter="url(#Filter_1)" xlinkHref="#Gear_Logo_Fill_Short_Arrow_0_Layer2_0_2_STROKES" />
                        <use filter="url(#Filter_1)" xlinkHref="#Gear_Logo_Fill_Short_Arrow_0_Layer2_0_3_STROKES" />
                    </g>
                    <g transform="matrix( 1, 0, 0, 1, 0,0) ">
                        <use filter="url(#Filter_1)" xlinkHref="#Gear" />
                    </g>
                    <g transform="matrix( 0.5398712158203125, 0.0539398193359375, -0.0539398193359375, 0.5398712158203125, 121.75,43.05) ">
                        <use filter="url(#Filter_1)" xlinkHref="#Gear" />
                    </g>
                    <g transform="matrix( 0.4481353759765625, -0.0116119384765625, 0.0116119384765625, 0.4481353759765625, 31.6,20.8) ">
                        <use filter="url(#Filter_1)" xlinkHref="#Gear" />
                    </g>
                </g>
            </svg>

            <table className="vendor1">
                <tbody>
                    {
                        Object.entries(data.vendor1).map(kvp => <tr key={kvp[0]}>
                            <th>{kvp[0]}</th>
                            <td className="bold">{kvp[1]}</td>
                        </tr>)
                    }
                </tbody>
            </table>

            <table className="vendor2">
                <tbody>
                    {
                        Object.entries(data.vendor2).map(kvp => <tr key={kvp[0]}>
                            <th>{kvp[0]}</th>
                            <td className="bold">{kvp[1]}</td>
                        </tr>)
                    }
                </tbody>
            </table>

            <ul className="client">
                <li className="bold">Bill To:</li>
                {
                    data.client.map((c, index) => <li key={index}>{c}</li>)
                }
            </ul>

            {
                data.tables.map((table, index) => <table key={index} className="mb-2">
                    <thead>
                        <tr>
                            {
                                table.headings.map(heading => <th key={heading} className="p-2">{heading}</th>)
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            table.rows.map((r, index) => <tr key={index}>{table.headings.map(heading => <td key={heading} className="p-2">{r[heading]}</td>)}</tr>)
                        }
                    </tbody>
                </table>)
            }

            {
                data?.customerNotes && Object.entries(data.customerNotes).map((kvp, i) => <React.Fragment key={i}>
                    <h4>{kvp[0]}</h4>
                    <p className="notes">{kvp[1]}</p>
                </React.Fragment>)
            }

            <ul className="grandtotal">
                <li className="bold">Subtotal:</li>
                <li>{data.subtotal}</li>

                {
                    data.misc && <>
                        <li className="bold">Misc:</li>
                        <li>{data.misc}</li>
                    </>
                }

                {
                    data.shipping && <>
                        <li className="bold">Shipping:</li>
                        <li>{data.shipping}</li>
                    </>
                }

                {
                    data.tax && <>
                        <li className="bold">Tax:</li>
                        <li>{data.tax}</li>
                    </>
                }
            </ul>

            <ul className="grandtotal">
                <li className="bold">Grand Total:</li>
                <li>{data.grandTotal}</li>
            </ul>
        </div>)
}

export default DocumentTotals;