import axios from "axios";
import React from "react";
import Loading from "../components/Loading";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { DataType, IDocument, IRecord } from "../interfaces.d";

type ItemTemporalTableProps = {
    id: string,
    apiController: string,
    updateHasInitialError(hasError: boolean) : void,
}

const FETCH_COUNT = 10;

function ItemTemporalTable({ apiController, id, updateHasInitialError } : ItemTemporalTableProps) {
    const [tableData, setTableData] = React.useState<IDocument>();
    const [startDate, setStartDate] = React.useState<Date>(null);
    const [skip, setSkip] = React.useState<number>(0);
    const [lastElement, setLastElement] = React.useState(null);
    const [dateTimeError, setDateTimeError] = React.useState<boolean>(false)

    const observer = React.useRef(
        new IntersectionObserver(
            (entries) => {
                const first = entries[0];
                if (first.isIntersecting) {
                    setSkip(s => s + FETCH_COUNT)
                }
            })
    );

    React.useEffect(() => {
        const currentElement = lastElement;
        const currentObserver = observer.current;

        if (currentElement) {
            currentObserver.observe(currentElement);
        }

        return () => {
            if (currentElement) {
                currentObserver.unobserve(currentElement);
            }
        };
    }, [lastElement]);

    React.useEffect(() => {
        axios.get<IDocument>(`/api/${apiController}/${id}/History?top=${FETCH_COUNT}${skip > 0 ? '&skip=' + skip : ''}${startDate ? '&startDate=' + startDate.toISOString() : ''}`)
            .then(response => {
                setTableData(tableData => {
                    if (!tableData) return response.data
                    else {
                        const tableDataCopy = JSON.parse(JSON.stringify(tableData))
                        const combinedData = [...tableDataCopy.data, ...response.data.data]
                        tableDataCopy.data = combinedData
                        return tableDataCopy
                    }
                })
            })
            .catch(_err => updateHasInitialError(true))
    }, [skip, startDate, apiController, id, updateHasInitialError])

    function handleDateUpdate(e: Date) {
        if (!e || isNaN(e.getTime()) || e.getFullYear().toString().split('').length < 4) {
             setDateTimeError(true)
             return
        }

        if (e.toLocaleDateString() !== startDate?.toLocaleDateString()) {
            setDateTimeError(false)
            setTableData(null); 
            setSkip(0); 
            setStartDate(e);
        }
    }

    function getTableData(record: IRecord) {
        if (!tableData) return;

        const property = tableData.schema.find(property => property.propertyName === record.propertyName);

        if (!property || property.displayOrder === -1) return;

        let display: any = record.displayValue ?? record.value;

        switch (property.type) {
            case DataType.Date:
                display = (record.value !== null) ? new Date(record.value).toLocaleDateString() : '';
                break;
            case DataType.DateTime:
                display = (record.value !== null) ? new Date(record.value).toLocaleString() : '';
                break;
            case DataType.Boolean:
                display = <input readOnly type="checkbox" checked={record.value} />;
                break;
            case DataType.Dropdown:
                display = property.staticChoices.find(c => parseInt(c.fieldValue) === record.value)?.displayName;
                break;
            default:
                break;
        }

        return display;
    }

    return (<>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    label="Start Date"
                    value={startDate}
                    onChange={handleDateUpdate}
                    renderInput={(params) => { 
                        params.error = dateTimeError
                        return <TextField {...params} />
                    }}
                />
            </LocalizationProvider>

            { !tableData ? <Loading /> :
                <TableContainer className="mt-1rem" component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Start Time</TableCell>
                                {tableData.schema.filter(s => s.displayOrder !== -1).map(property => 
                                    <TableCell align="right" className="cursor-pointer" key={property.propertyName}>{property.displayName ?? property.propertyName}</TableCell>)}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableData.data?.map((data, i) => {
                                return (tableData.data.length < tableData.totalCount && i === (tableData.data.length - 1)) 
                                ? 
                                <TableRow ref={setLastElement} key={i} data-testid="table-row">
                                    <td>{new Date(data.periodStart).toLocaleString()}</td>
                                    {data.records?.map((record, index) => <React.Fragment key={index}>{getTableData(record)}</React.Fragment>)}
                                </TableRow> 
                                : 
                                <TableRow key={i} data-testid="table-row">
                                    <TableCell>{new Date(data.periodStart).toLocaleString()}</TableCell>
                                    {data.records?.map((record, index) => <TableCell align="right" key={index}>{getTableData(record)}</TableCell>)}
                                </TableRow>
                            })}
                        </TableBody>
                    </Table>
                </TableContainer> }
        </>
    )
}

export default ItemTemporalTable