import axios from "axios"
import React from "react"
import { DataType, FieldDisplayType, IData, IDocument, IProperty, IRecord, ILineConversionData, ISelectedPicker } from "../interfaces.d"
import ItemPicker from "../item/ItemPicker"
import { Link, useNavigate, useParams } from "react-router-dom"
import Loading from "../components/Loading"
import { DataContext } from "../App"
import { Spinner } from "react-bootstrap"
import SomethingWentWrong from "../components/SomethingWentWrong"

interface IConversionLine {
    data: IData,
    schema: IProperty[],
    standalone: IDocument,
    selectedLines: number[],
    setSelectedLines(updatedLines: number[]),
    selectedPicker: ISelectedPicker,
    setSelectedPicker(picker: ISelectedPicker): void,
    values: any,
    fieldErrors: FieldError[],
}

type FieldError = {
    id: number,
    property: string,
    message: string,
}

type LineConversionParams = {
    area: string,
    controller: string,
    action: string,
    id: string,
    convertToController?: string,
}

function StandardLineConversion() {
    const { area, controller, action, id } = useParams<LineConversionParams>()
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
    const [initialError, setInitialError] = React.useState<boolean>(false);
    const [selectedLines, setSelectedLines] = React.useState<number[]>([]);
    const [selectedPicker, setSelectedPicker] = React.useState<ISelectedPicker>();
    const [serverError, setServerError] = React.useState<string>();
    const navigate = useNavigate();
    const data = React.useContext(DataContext).find(vr => vr.identifier === controller);
    const [standaloneErrors, setStandaloneErrors] = React.useState<any>({});
    const [fieldErrors, setFieldErrors] = React.useState<FieldError[]>([]);
    const [conversionData, setConversionData] = React.useState<ILineConversionData>()
    document.title = `${data?.display ?? data?.identifier ?? ''} ${conversionData?.parentDescription ?? ''} ${conversionData?.title ?? ''} - Decagon`

    React.useEffect(() => {
        axios.get<ILineConversionData>(`/api/${data.controller}/Get${action}Data/${id}`)
            .then(response => setConversionData(response.data))
            .catch(_err => setInitialError(true))
    }, [data, action, id])

    const handleSubmit = (event: any) => {
        event.preventDefault();
        setIsSubmitting(true);
        setStandaloneErrors({});
        setServerError('');
        setFieldErrors([]);
        let hasStandaloneErrors = false;

        let submittedData = JSON.parse(JSON.stringify(conversionData)) as ILineConversionData;

        // Standalone required checks
        conversionData.standalone?.schema.filter(p => p.isRequired).forEach(rf => {
            if (!conversionData.standalone.data[0].records.find(d => d.propertyName === rf.propertyName).value) {
                const errCopy = JSON.parse(JSON.stringify(standaloneErrors));
                errCopy[rf.propertyName] = (rf.displayName ?? rf.propertyName) + " is required.";
                setStandaloneErrors(errCopy);
                if (!hasStandaloneErrors) hasStandaloneErrors = true;
            }
        });

        // Determine whether to filter out non-selected lines or convert all
        if (selectedLines.length > 0) submittedData.info.data = submittedData.info.data.filter(d => selectedLines.includes(d.records.find(r => r.propertyName === conversionData.info.schema.find(p => p.isKey).propertyName).value));

        // Field Error checks
        const fieldErrors: FieldError[] = [];
        const lineKey = conversionData.info.schema.find(p => p.isKey);

        // Type checks
        const mutableProperties = submittedData.info.schema.filter(s => s.canUpdate);
        submittedData.info.data.forEach(d =>
            d.records.filter(r => mutableProperties.find(p => p.propertyName === r.propertyName) && r.value).forEach(r => {
                const property = mutableProperties.find(p => p.propertyName === r.propertyName);
                if (property.type === DataType.Decimal) {
                    try {
                        parseFloat(r.value);
                    } catch {
                        fieldErrors.push({ id: d.records.find(r => r.propertyName === lineKey.propertyName).value, message: r.propertyName + " must be a valid integer.", property: r.propertyName });
                    }
                }
                else if (property.type === DataType.Int) {
                    try {
                        parseInt(r.value);
                    } catch {
                        fieldErrors.push({ id: d.records.find(r => r.propertyName === lineKey.propertyName).value, message: r.propertyName + " must be a valid integer.", property: r.propertyName });
                    }
                }
            })
        );

        // Required checks
        const requiredProperties = submittedData.info.schema.filter(s => s.isRequired);
        submittedData.info.data.forEach(d =>
            d.records.filter(r => requiredProperties.find(p => p.propertyName === r.propertyName) && !r.value).forEach(r => {
                fieldErrors.push({ id: d.records.find(r => r.propertyName === lineKey.propertyName).value, message: r.propertyName + " is required.", property: r.propertyName })
            })
        );

        if (hasStandaloneErrors || fieldErrors.length > 0) {
            setFieldErrors(fieldErrors);
            setIsSubmitting(false);
            return;
        }

        axios.post(`/api/${data.controller}/${action}/${id}`, submittedData)
            .then(response => navigate(`/${conversionData.convertToArea ?? area}/${conversionData.convertToController}/Item/${response.data}`))
            .catch(error => {
                if (error.response) {
                    setServerError(error.response.data);
                    setIsSubmitting(false);
                }
            })
    }

    const getStandalone = (standaloneProp: IProperty) => {
        // TODO: Add support for other types of standalone documents

        switch (standaloneProp.displayType) {
            case FieldDisplayType.Picker:
                const standaloneSchemas = JSON.parse(JSON.stringify(conversionData.standalone.schema));
                const infoSchemas = JSON.parse(JSON.stringify(conversionData.info.schema));
                const combinedSchemas = standaloneSchemas.concat(infoSchemas);
                const standaloneRecord = conversionData.standalone.data[0].records.find(r => r.propertyName === standaloneProp.propertyName);

                const values = {};
                values[standaloneProp.propertyName] = standaloneRecord.value;

                return <ItemPicker
                    values={values}
                    schema={combinedSchemas}
                    property={standaloneProp}
                    record={standaloneRecord}
                    selectedPicker={selectedPicker}
                    setSelectedPicker={updateSelectedPicker}
                    setFieldValue={setFieldValue}
                />
            default:
                return <></>
        }
    }

    const updateSelectedLines = (updatedLines: number[]) => setSelectedLines(updatedLines)

    const updateSelectedPicker = (picker: ISelectedPicker) => setSelectedPicker(picker)

    const setFieldValue = (property: string, value?: number) => {
        // Check if property to update is a standalone picker or not
        const standalonePicker = conversionData.standalone.schema.find(p => p.propertyName === property);

        if (standalonePicker) {
            const record = conversionData.standalone.data[0].records.find(r => r.propertyName === property);
            record.value = value;
        }
    }

    const getValues = (data: IData) => {
        const values: any = {};

        data.records.map(r => values[r.propertyName] = r.value);
        if (conversionData.standalone) conversionData.standalone.data[0].records.map(r => values[r.propertyName] = r.value);

        return values;
    }

    return (initialError ? <SomethingWentWrong /> : !conversionData ? <Loading /> :
        <>
            <h2>{conversionData.title}</h2>

            {
                conversionData.info.data.length === 0 ? <div className="alert alert-light">This document does not contain any convertable lines.</div> :
                    <>
                        <div className="mb-2">
                            {
                                conversionData.standalone?.schema.map(p =>
                                    <React.Fragment key={p.propertyName}>
                                        {getStandalone(p)}
                                        {standaloneErrors[p.propertyName] && <div className="form-error">{standaloneErrors[p.propertyName]}</div>}
                                    </React.Fragment>)
                            }
                        </div>

                        {
                            serverError && <div className="form-error mb-2">&bull; {serverError}</div>
                        }

                        <form onSubmit={handleSubmit}>
                            <button disabled={isSubmitting} type="submit" className="btn btn-primary">
                                {`${conversionData.buttonText ?? 'Convert'}${selectedLines.length === 0 ? ' All' : ' Selected'}`}
                                {isSubmitting && <Spinner animation="border" className="ml-2" size="sm" color="white" />}
                            </button>

                            {
                                conversionData.info.data.map((data, index) => <StandardConversionLine
                                    key={index}
                                    data={data}
                                    schema={conversionData.info.schema}
                                    standalone={conversionData.standalone}
                                    selectedLines={selectedLines}
                                    setSelectedLines={updateSelectedLines}
                                    selectedPicker={selectedPicker}
                                    setSelectedPicker={setSelectedPicker}
                                    values={getValues(data)}
                                    fieldErrors={fieldErrors}
                                />)
                            }

                            <button disabled={isSubmitting} type="submit" className="btn btn-primary mt-2">
                                {`${conversionData.buttonText ?? 'Convert'}${selectedLines.length === 0 ? ' All' : ' Selected'}`}
                                {isSubmitting && <Spinner animation="border" className="ml-2" size="sm" color="white" />}
                            </button>
                        </form>
                    </>
            }

            <Link to={`/${area}/${controller}/Item/${id}`}>
                <img alt="Back" className="table-img cursor-pointer mb-2" title="Back" src="/images/glyphicons-basic-223-chevron-left.svg" />
            </Link>
        </>)
}

function StandardConversionLine({ data, schema, selectedLines, setSelectedLines, selectedPicker, setSelectedPicker, values, fieldErrors }: IConversionLine) {
    const checkboxRef = React.useRef<HTMLInputElement>();

    // Row Helpers
    const rowId = data.records.find(r => r.propertyName === schema.find(p => p.isKey).propertyName).value;
    const columnSize = 12 / (data.records.length - 1);

    const getProperty = (record: IRecord) => {
        return schema.find(s => s.propertyName === record.propertyName);
    }

    const updateCheckbox = () => {
        let selectedLinesCopy = [...selectedLines];

        if (checkboxRef.current.checked && selectedLinesCopy.indexOf(rowId) === -1) selectedLinesCopy.push(rowId)
        else if (!(checkboxRef.current.checked) && selectedLinesCopy.indexOf(rowId) !== -1) selectedLinesCopy = selectedLinesCopy.filter(n => n !== rowId)

        setSelectedLines(selectedLinesCopy);
    }

    const updateInput = (event: any, record: IRecord) => {
        const property = getProperty(record);
        const value = event.target.value;

        switch (property.type) {
            case DataType.Decimal:
                if (!(isNaN(value))) record.value = parseFloat(value);
                break;
            case DataType.Int:
                if (!(isNaN(value))) record.value = parseInt(value);
                break;
            case DataType.String:
            default:
                record.value = value;
                break;
        }
    }

    const setFieldValue = (property: string, value?: number) => {
        const rec = data.records.find(p => p.propertyName === property);

        if (rec) {
            rec.value = value;
        }
    }

    const getInputGroup = (record: IRecord) => {
        const property = getProperty(record);

        let body;
        switch (property.displayType) {
            case FieldDisplayType.Picker:
                return <>
                    <ItemPicker
                        disabled={selectedLines.length > 0 && selectedLines.indexOf(rowId) === -1}
                        schema={schema}
                        values={values}
                        setFieldValue={setFieldValue}
                        selectedPicker={selectedPicker}
                        setSelectedPicker={setSelectedPicker}
                        property={property}
                        record={record} />
                    {fieldErrors.find(f => f.id === rowId && f.property === property.propertyName) &&
                        <div className="form-error">{fieldErrors.find(f => f.id === rowId && f.property === property.propertyName).message}</div>}
                </>
            default:
                if (property.canUpdate) {
                    switch (property.type) {
                        case DataType.Decimal:
                        case DataType.Int:
                            body = <input disabled={selectedLines.length > 0 && selectedLines.indexOf(rowId) === -1} defaultValue={record.value} className="form-control" step={property.type === DataType.Decimal ? '0.01' : ''} onChange={(e) => updateInput(e, record)} type="number" />
                            break;
                        default:
                            body = <input disabled={selectedLines.length > 0 && selectedLines.indexOf(rowId) === -1} defaultValue={record.value} className="form-control" onChange={(e) => updateInput(e, record)} type="text" />
                            break;
                    }
                } else {
                    body = <span className="form-control">{record.displayValue ?? record.value}</span>
                    break;
                }
        }

        return <>
            <div className="input-group">
                {data.records.filter(r => !getProperty(r).isKey).indexOf(record) === 0 &&
                    <div className="input-group-prepend">
                        <div className="input-group-text">
                            <input ref={checkboxRef} type="checkbox" onClick={updateCheckbox} />
                        </div>
                    </div>}

                <div className="input-group-prepend">
                    <div className="input-group-text">
                        {property.displayName ?? property.propertyName}
                    </div>
                </div>

                {body}
            </div>
            {fieldErrors.find(f => f.id === rowId && f.property === property.propertyName) &&
                <div className="form-error">{fieldErrors.find(f => f.id === rowId && f.property === property.propertyName).message}</div>}
        </>
    }

    return <div className="row">
        {
            data.records.filter(r => !getProperty(r).isKey).map((record, index) =>
                <div key={index} className={`col-${columnSize} mt-2`}>
                    {getInputGroup(record)}
                </div>
            )
        }
    </div>
}

export default StandardLineConversion;