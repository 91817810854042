import { useContext } from "react";
import { DataContext } from "../App";
import { useParams } from "react-router-dom";
import SomethingWentWrong from "./SomethingWentWrong";

interface ItemRouteProps {
    component: any,
}

function ItemRoute({ component: RouteComponent }: ItemRouteProps) {
    const { area, controller } = useParams();
    const dataContext = useContext(DataContext);

    if ((area && controller) && !dataContext.find(vr => vr.area === area && vr.identifier === controller)) return <SomethingWentWrong />

    return <RouteComponent />
}

export default ItemRoute;