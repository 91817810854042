import axios from "axios";
import React from "react";
import { IDocument, IBaseInfo, FieldDisplayType, ItemDisplayType, ItemParams } from "../interfaces.d";
import ItemForm from "./ItemForm";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../components/Loading";
import { DataContext } from "../App";
import SomethingWentWrong from "../components/SomethingWentWrong";
import ItemDisplay from "./ItemDisplay";

function Item(): JSX.Element {
    const { area, controller, id, independentId } = useParams<ItemParams>();
    const [loading, setLoading] = React.useState<boolean>(true);
    const [initialLoadFailure, setInitialLoadFailure] = React.useState<boolean>(false);
    const [documentData, setDocumentData] = React.useState<IDocument>(null);
    const [itemDisplayType, setItemDisplayType] = React.useState<ItemDisplayType | null>();
    const [baseInfo, setBaseInfo] = React.useState<IBaseInfo>();
    const data = React.useContext(DataContext).find(vr => vr.identifier === controller);
    const navigate = useNavigate();

    const splitURL = window.location.href.split('/').filter(s => s);
    const dependentObj = independentId && data.children.find(c => c.identifier === splitURL[splitURL.length - (id ? 2 : 1)]);

    const documentUrl: string = `/api/${dependentObj?.controller ?? data.controller}/${id ? id : `Defaults${independentId ? `?independentId=${independentId}` : ''}`}`;

    let title = (dependentObj?.displayName ?? dependentObj?.controller) ?? (data.display ?? data.identifier);
    if (documentData?.data?.length > 0) title += ` ${documentData.data[0].records.find(r => r.propertyName === documentData.schema.find(p => p.displayType === FieldDisplayType.Span)?.propertyName)?.value ?? ''}`;
    title += ' - Decagon';
    document.title = title;
    
    const invokeCreate = (values: any) => axios.post(`/api/${dependentObj?.controller ?? data.controller}${independentId ? `?independentId=${independentId}` : ''}`, values);
    const invokeEdit = (values: any) => axios.put(`/api/${dependentObj?.controller ?? data.controller}/${id}`, values);

    const postCreate = (newId: number) => navigate(`/${area}/${data.identifier}/Item/${independentId ? independentId : newId}`);
    const postEdit = (_id: number) => {  setLoading(true); setItemDisplayType(ItemDisplayType.Details); }

    const handleImgClick = (clickedType: ItemDisplayType | null) => {
        if (clickedType !== itemDisplayType) {
            setItemDisplayType(clickedType);
        }
    };

    React.useEffect(() => {
        setItemDisplayType(id || (independentId && id) ? ItemDisplayType.Details : null);

        return () => {
            setBaseInfo(null);
            setItemDisplayType(null);
            setDocumentData(null);
            setLoading(true);
        }
    }, [id, independentId]);

    React.useEffect(() => {   
        if (!loading) return

        axios.get<IBaseInfo>(`/api/${dependentObj?.controller ?? data.controller}/Info`)
            .then(response => {
                setBaseInfo(response.data);
            })

        axios.get<IDocument>(documentUrl)
            .then(response => {
                setDocumentData(response.data);
            })
            .catch(_err => setInitialLoadFailure(true));

        setLoading(false);
    }, [data, dependentObj, loading, documentUrl]);

    return (initialLoadFailure ? <SomethingWentWrong /> :
        (loading || !documentData) ? <Loading /> : itemDisplayType !== null ? <ItemDisplay 
            baseInfo={baseInfo}
            data={data}
            dependentObj={dependentObj}
            documentData={documentData}
            itemDisplayType={itemDisplayType}
            handleImgClick={handleImgClick}
        />
            : <>
                <ItemForm documentData={documentData} ajaxCallback={id ? invokeEdit : invokeCreate} documentName={dependentObj ? (dependentObj.displayName ?? dependentObj.controller) :  data.display ?? controller} actionDisplayName={id ? "Edit" : "Create"} changeType={id ? postEdit : postCreate} />
                <div className="mt-2">
                    <img alt="Back" className="table-img cursor-pointer" onClick={() => {
                        if (id) setItemDisplayType(ItemDisplayType.Details)
                        else navigate(`/${area}/${controller}${independentId ? `/Item/${independentId}` : ''}`)
                    }} title="Back" src="/images/glyphicons-basic-223-chevron-left.svg" />
                </div>
            </>
    );
}

export default Item