import React from "react";
import * as yup from "yup";
import ItemPicker from "./ItemPicker";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { Alert, Button } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { DataType, IDocument, IProperty, FieldDisplayType } from "../interfaces.d";
import Loading from "../components/Loading";

type ItemFormProps = {
    documentData: IDocument,
    ajaxCallback: any,
    documentName?: string,
    actionDisplayName?: string,
    changeType?: (id: number) => void,
}

function ItemForm({ documentData, ajaxCallback, actionDisplayName, documentName, changeType }: ItemFormProps): JSX.Element {
    const [loading, setLoading] = React.useState<boolean>(true);
    const [schema, setSchema] = React.useState<any>();
    const [initialValues, setInitialValues] = React.useState<any>();
    const [selectedPicker, setSelectedPicker] = React.useState<{ property: IProperty, id: number }>();
    const [serverError, setServerError] = React.useState<string>();

    React.useEffect(() => {
        if (documentData) {
            // Object that will be passed into Yup.object, contains key value pairs for
            // fieldName: yupObjectType
            const yupObject: { [key: string]: yup.BaseSchema } = {};
            const initialValues: { [key: string]: any } = {};

            // For each field in our metadata we must create the appropriate 
            // property in the Yup object.
            documentData.schema.filter(p => p.canUpdate).forEach(property => {
                switch (property.type) {
                    case DataType.Tax:
                    case DataType.Currency:
                    case DataType.Decimal:
                    case DataType.Money:
                    case DataType.Int:
                        yupObject[property.propertyName] = yup.number().typeError(`${property.displayName} is required.`);
                        break
                    case DataType.String:
                        let strValidation = yup.string();

                        if (property.maxLength) {
                            strValidation = strValidation.max(property.maxLength);
                        }

                        yupObject[property.propertyName] = strValidation;
                        break
                    case DataType.DateTime:
                    case DataType.Date:
                        yupObject[property.propertyName] = yup.date().typeError(`${property.displayName} must be a valid Date.`);
                        break
                    case DataType.Dropdown:
                        let ddValidation = yup.number();
                        if (property.staticChoices.length > 0) {
                            const fieldValues = property.staticChoices.map(choice => parseInt(choice.fieldValue));
                            ddValidation = ddValidation.oneOf(fieldValues);
                        }

                        yupObject[property.propertyName] = ddValidation;
                        break
                    case DataType.Boolean:
                        yupObject[property.propertyName] = yup.boolean();
                        break
                    default:
                        break
                }

                if (property.isRequired) {
                    yupObject[property.propertyName] = yupObject[property.propertyName].required(`${property.displayName} is required.`);
                }
                else {
                    yupObject[property.propertyName] = yupObject[property.propertyName].nullable();
                }

                const propertyRecord = documentData.data && documentData.data[0].records.find(r => r.propertyName === property.propertyName);

                switch (property.type) {
                    case DataType.Tax:
                    case DataType.Decimal:
                    case DataType.Money:
                        initialValues[property.propertyName] = propertyRecord?.value ?? '';
                        break
                    case DataType.Dropdown:
                        initialValues[property.propertyName] = propertyRecord?.value ?? 0;
                        break
                    case DataType.Int:
                        if (property.displayOrder === -1) {
                            initialValues[property.propertyName] = propertyRecord?.value ?? 0;
                        }
                        else if (propertyRecord?.value === 0 && property.displayType === FieldDisplayType.Picker) {
                            initialValues[property.propertyName] = null;
                        }
                        else {
                            initialValues[property.propertyName] = propertyRecord?.value ?? '';
                        }
                        break;
                    case DataType.Boolean:
                        initialValues[property.propertyName] = propertyRecord?.value ? propertyRecord?.value : false;
                        break;
                    case DataType.DateTime:
                    case DataType.Date:
                        initialValues[property.propertyName] = (propertyRecord?.value && propertyRecord.value !== 'Z') ? new Date(propertyRecord?.value) : '';
                        break;
                    default:
                        if (property.displayType === FieldDisplayType.Picker && property.isRequired && propertyRecord?.value === 0) {
                            initialValues[property.propertyName] = '';
                        }
                        else {
                            initialValues[property.propertyName] = propertyRecord?.value ?? '';
                        }
                        break;
                }
            });

            setSchema(yup.object(yupObject));
            setInitialValues(initialValues);
            setLoading(false);
        }
    }, [documentData])

    const handleCheckbox = (e: any, booleanProperty: IProperty, setFieldValue: any) => {
        setFieldValue(booleanProperty.propertyName, e.target.checked);
    }

    const getField = (property: IProperty, setFieldValue: any, values: any) => {
        switch (property.displayType) {
            case FieldDisplayType.TextArea:
                return <Field key={property.propertyName} as="textarea" rows={3} name={property.propertyName} id={property.propertyName} className="form-control" />;
            default:
                switch (property.type) {
                    case DataType.Dropdown:
                        return <Field key={property.propertyName} as="select" id={property.propertyName} name={property.propertyName} className="form-control">
                            {
                                property.staticChoices.length > 0 && property.staticChoices.map(choice => <option key={choice.fieldValue} value={choice.fieldValue}>{choice.displayName ?? choice.fieldName}</option>)
                            }
                        </Field>;
                    case DataType.Boolean:
                        return <Field as="input" checked={values[property.propertyName]} onChange={(e: any) => handleCheckbox(e, property, setFieldValue)} type="checkbox" key={property.propertyName} id={property.propertyName} name={property.propertyName} />;
                    default:
                        // Determine Dependencies
                        let fieldDisabled: boolean = false;

                        if (property.dependentRelationship) {
                            const independentProperty = documentData.schema.find(p => p.propertyName === property.dependentRelationship.propertyName);

                            if (!independentProperty) return;

                            const independentValue = values[independentProperty.propertyName];

                            switch (independentProperty.type) {
                                case DataType.Boolean:
                                    if (!independentValue) fieldDisabled = !fieldDisabled;
                                    break;
                                default:
                                    break
                            }
                        }
                        else if (getRecord(property.propertyName)) fieldDisabled = !getRecord(property.propertyName).isEnabled

                        return <Field data-testid={property.propertyName + "-input"} disabled={fieldDisabled} key={property.propertyName} name={property.propertyName} id={property.propertyName} className="form-control" />;
                }
        }
    }

    const getLocalizedDate = (date: Date, type: DataType): string => {
        const formattedDate = format(date, type === DataType.DateTime ? 'MM/dd/yyyy hh:mm:ss aa' : 'mm/dd/yyyy');
        return formattedDate;
    }

    const getColumn = (property: IProperty, setFieldValue: any, values: any) => {
        let body;
        const dependents = documentData.schema.filter(p => p.dependentRelationship?.propertyName === property.propertyName
            && (p.dependentRelationship?.prependDisplay || p.dependentRelationship?.appendDisplay)
            && !(p.dependentRelationship?.appendDisplay && p.dependentRelationship?.prependDisplay));

        const renderDependencies = (isAppend: boolean, showPropertyName: boolean = true) => {
            return (
                dependents?.filter(p => isAppend ? p.dependentRelationship.appendDisplay : p.dependentRelationship.prependDisplay).sort((a, b) => b.dependentRelationship.displayOrder - a.dependentRelationship.displayOrder).map((p, index) => {
                    return <React.Fragment key={index}>
                        {showPropertyName && <div className="input-group-prepend">
                            <div className="input-group-text">
                                {p.displayName ?? p.propertyName}
                            </div>
                        </div>}
                        {getField(p, setFieldValue, values)}
                    </React.Fragment>;
                })
            )
        }

        switch (property.displayType) {
            case FieldDisplayType.Picker:
                const record = getRecord(property.propertyName);
                body = <ItemPicker appendElement={dependents[0]?.propertyName} schema={documentData.schema} values={values} setFieldValue={setFieldValue} selectedPicker={selectedPicker} setSelectedPicker={setSelectedPicker} property={property} record={record} />;
                break
            default:
                switch (property.type) {
                    case DataType.Date:
                    case DataType.DateTime:
                        body = <DatePicker
                            selected={values[property.propertyName] ? new Date(values[property.propertyName].toLocaleString()) : new Date()}
                            showTimeSelect={property.type === DataType.DateTime}
                            customInput={
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">
                                            {property.displayName ?? property.propertyName}
                                        </div>
                                    </div>
                                    <span className="form-control cursor-pointer border-right-0">
                                        {values[property.propertyName] ? getLocalizedDate(values[property.propertyName], property.type) : ''}
                                    </span>

                                    <div className="input-group-append cursor-pointer">
                                        <div className="input-group-text" style={{ backgroundColor: "transparent" }}>
                                            <img alt="Calendar" className="table-img" src="/images/glyphicons-basic-46-calendar.svg" />
                                        </div>
                                    </div>
                                </div>
                            }
                            id={property.propertyName}
                            name={property.propertyName}
                            onChange={e => {
                                setFieldValue(property.propertyName, e)
                            }}
                        />;
                        break;
                    case DataType.Boolean:
                        // TODO #675: When it comes to boolean fields with prepends/appends we should determine if
                        //         we should show the propertyName of not, for now we wont.
                        body = <div className="input-group">
                            {renderDependencies(false)}

                            <div className="input-group-prepend">
                                <div className="input-group-text">
                                    {getField(property, setFieldValue, values)}
                                </div>
                            </div>

                            {dependents?.length === 0 && <span className="form-control">{property.displayName ?? property.propertyName}</span>}

                            {renderDependencies(true)}
                        </div>
                        break;
                    default:
                        body = <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text">
                                    {property.displayName ?? property.propertyName}
                                </div>

                                {property.currencyProperty && <span className="input-group-text" data-currency="">{getRecord(property.currencyProperty)?.displayValue}</span>}
                            </div>

                            {getField(property, setFieldValue, values)}
                        </div>;
                        break;
                }
        }

        return <div className="col-md-6 mt-2">
            {body}
            <div>
                <ErrorMessage data-testid="form-error" className="form-error" name={property.propertyName} component="div" />
            </div>
        </div>
    }

    const getRecord = (property: string) => {
        return documentData.data ? documentData.data[0].records.find(r => r.propertyName === property) : null;
    }

    return (
        <>
            <h2>{actionDisplayName}</h2>
            {documentName && <h4>{documentName}</h4>}
            {serverError && <div className="form-error mb-2">&bull; {serverError}</div>}
            <hr />

            {
                loading ? <Loading /> :
                    documentData ?
                        <Formik
                            enableReinitialize={true}
                            initialValues={initialValues}
                            validationSchema={schema}
                            onSubmit={async (values, { setSubmitting }) => {
                                const submitted: any = JSON.parse(JSON.stringify(values));
                                setServerError('');

                                documentData.schema.filter(s => s.type === DataType.Dropdown).forEach(s => submitted[s.propertyName] = parseInt(submitted[s.propertyName]));
                                documentData.schema.filter(s => !s.isRequired && submitted[s.propertyName] === "").forEach(s => submitted[s.propertyName] = null);

                                /*documentData.schema.filter(s => s.type === DataType.DateTime).forEach(s => {
                                    const currentDate = submitted[s.propertyName];
                                    if (currentDate) {
                                        const tz = new Date(currentDate).getTimezoneOffset() * 60000;
                                        submitted[s.propertyName] = new Date(new Date(currentDate).getMinutes() - tz);
                                    }
                                })*/

                                try {
                                    const resp = await ajaxCallback(submitted);
                                    if (changeType) changeType(resp.data);
                                }
                                catch (e) {
                                    if (e.response) {
                                        setServerError(e.response.data.title ? e.response.data.title : e.response.data);
                                    }
                                }
                            }}
                        >
                            {
                                ({ setFieldValue, isSubmitting, values }) =>
                                    <Form>
                                        <div key="form-row" className="row mt-2">
                                            {
                                                documentData.schema.filter(property => property.displayOrder !== -1
                                                    && property.canUpdate
                                                    && (!property.dependentRelationship?.appendDisplay && !property.dependentRelationship?.prependDisplay))
                                                    .map((property, index) =>
                                                        <React.Fragment key={property.propertyName}>
                                                            {getColumn(property, setFieldValue, values)}
                                                        </React.Fragment>
                                                    )
                                            }
                                        </div>
                                        <Button className="mt-2" type="submit" disabled={isSubmitting}>Submit</Button>
                                    </Form>
                            }
                        </Formik>

                        :

                        <Alert variant="primary">This form appears to have no input fields.</Alert>
            }
        </>
    );
}

export default ItemForm;