import * as React from "react";
import { v4 as uuidv4 } from "uuid";
import { IProperty } from "../interfaces.d";
import { Modal } from "react-bootstrap";
import { Fab } from "@mui/material";
import SortIcon from '@mui/icons-material/Sort';

interface ISorting {
    setSort(sort: string): void,
    properties: IProperty[],
}

interface ISortRow {
    id: string,
    column: string,
    navigationDisplayProperty: string,
    isDesc: boolean,
}

function Sorting({ setSort, properties }: ISorting) {
    const [fieldsInView, setFieldsInView] = React.useState<ISortRow[]>([{ id: uuidv4(), column: '', navigationDisplayProperty: '', isDesc: true }]);
    const [tempFieldsInView, setTempFieldsInView] = React.useState<ISortRow[]>([{ id: uuidv4(), column: '', navigationDisplayProperty: '', isDesc: true }]);
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string>();

    const addNewField = () => {
        const tempFieldsCopy = [...tempFieldsInView];
        tempFieldsCopy.push({ id: uuidv4(), column: '', navigationDisplayProperty: '', isDesc: true });
        setTempFieldsInView(tempFieldsCopy);
    }

    const selectField = (event: any, row: ISortRow) => {
        const tempFieldsCopy = [...tempFieldsInView];

        if (event.currentTarget.value === '') {
            const tempFieldIndex = tempFieldsCopy.indexOf(row);
            tempFieldsCopy[tempFieldIndex].column = '';
        } else {
            // Get Information
            const info = properties.find(p => p.propertyName === event.currentTarget.value || p.navigationProperty === event.currentTarget.value);
            if (info) {
                const tempFieldIndex = tempFieldsCopy.indexOf(row);

                tempFieldsCopy[tempFieldIndex].column = info.navigationProperty ?? info.propertyName;
                tempFieldsCopy[tempFieldIndex].navigationDisplayProperty = info.navigationDisplayProperty;
                setTempFieldsInView(tempFieldsCopy);
            }
        }
    }

    const selectOrder = (event: any, row: ISortRow) => {
        const tempFieldsCopy = [...tempFieldsInView];
        const tempFieldIndex = tempFieldsCopy.indexOf(row);

        if (tempFieldsCopy[tempFieldIndex].column !== "") {
            switch (event.currentTarget.value) {
                case "Descending":
                    tempFieldsCopy[tempFieldIndex].isDesc = true;
                    break
                case "Ascending":
                    tempFieldsCopy[tempFieldIndex].isDesc = false;
                    break
                default:
                    return;
            }

            setTempFieldsInView(tempFieldsCopy);
        }
    }

    const apply = () => {
        setError('');
        const tempFieldsCopy = [...tempFieldsInView];

        // Error check
        if (tempFieldsCopy.find(fc => fc.column === '')) {
            setError('Column is required.');
            return;
        } else if (tempFieldsCopy.length === 0) {
            setError('Sorting criteria is required.');
            return;
        }

        let sortString = '';

        tempFieldsCopy.forEach((row, index) => {
            sortString += `${row.column}${row.navigationDisplayProperty ? `/${row.navigationDisplayProperty}` : ''}`;
            if (row.isDesc) sortString += ' desc';
            sortString += tempFieldsCopy.length === (index + 1) ? "" : ",";
        })

        setSort(sortString);
        setShowModal(false);

        // Move temporary fields into fields in view
        setFieldsInView(JSON.parse(JSON.stringify(tempFieldsCopy)));
    }

    const removeRow = (_event: any, row: ISortRow) => {
        let tempFieldsCopy = [...tempFieldsInView];

        if (tempFieldsCopy.length === 1) {
            setError('At least one sorting criteria is required.');
            return;
        }

        tempFieldsCopy = tempFieldsCopy.filter(field => field.id !== row.id);
        setTempFieldsInView(tempFieldsCopy);
    }

    const handleClose = () => {
        setError('');
        setShowModal(false);

        const fieldsCopy = JSON.parse(JSON.stringify([...fieldsInView]));
        setTempFieldsInView(fieldsCopy);
    }

    const clear = () => {
        setSort('');
        setTempFieldsInView([{ id: uuidv4(), column: '', navigationDisplayProperty: '', isDesc: true }]);
        setFieldsInView([{ id: uuidv4(), column: '', navigationDisplayProperty: '', isDesc: true }]);
        setShowModal(false);
    }

    const getField = (field: ISortRow) => {
        return <div className="form-row mt-2">
            <div className="col-md-6">
                <div className="input-group">
                    <div className="input-group-prepend" style={{ cursor: "pointer" }}>
                        <span onClick={(e) => removeRow(e, field)} className="input-group-text">&times;</span>
                    </div>
                    <select value={field.column} onChange={(e) => selectField(e, field)} className="custom-select" asp-items="sorts">
                        <option>&nbsp;</option>
                        {properties.map(property => <option key={property.propertyName} value={property.navigationProperty ?? property.propertyName}>{property.displayName ?? property.propertyName}</option>)}
                    </select>
                </div>
            </div>
            <div className="col-md-6">
                <select value={field.isDesc ? "Descending" : "Ascending"} onChange={(e) => selectOrder(e, field)} className="custom-select">
                    <option value="Descending">Descending</option>
                    <option value="Ascending">Ascending</option>
                </select>
            </div>
        </div>
    }

    return (
        <>
            <Fab sx={{ bgcolor: 'white', mb: 2 }} onClick={() => setShowModal(true)} variant="extended">
                <SortIcon className="mr-1" />
                Sort
            </Fab>

            <Modal size="lg" show={showModal} onHide={handleClose} tabIndex={-1} role="dialog" data-label="sort">
                <Modal.Header>
                    <h4>Sorting</h4>
                    <button className="close" onClick={handleClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="row spaced-container mb-2">
                        <button onClick={addNewField} type="button" className="btn btn-primary mx-2">Add Field</button>
                        {
                            fieldsInView.find(fi => fi.column) &&
                            <img
                                onClick={clear}
                                src="/images/glyphicons-basic-250-eraser.svg"
                                className="table-img cursor-pointer mr-4 mt-2"
                                alt="Clear Filter"
                            />
                        }
                    </div>

                    {error && <div className="form-error pb-1">&bull; {error}</div>}

                    <form>
                        <div className="form-row">
                            <div className="col-md-6">Column</div>
                            <div className="col-md-6">Order</div>
                        </div>
                        {tempFieldsInView.map((field, index) =>
                            <React.Fragment key={index}>{getField(field)}</React.Fragment>
                        )}
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={handleClose} type="button" className="btn btn-secondary">Discard Changes</button>
                    <button onClick={apply} type="button" className="btn btn-primary">Apply</button>
                </Modal.Footer>
            </Modal>
        </>);
}

export default Sorting;